import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { defaultLocale } from '../../../lang/config';
import cn from 'classnames';
import LocalizedLink from './LocalizedLink';

class LanguageSwitch extends PureComponent {
    static defaultProps = {
        params: {},
        translatableUrlParams: {},
    };

    static propTypes = {
        params: PropTypes.object,
        translatableUrlParams: PropTypes.object,
    };

    formatParams(lang) {
        const { params, translatableUrlParams } = this.props;
        if (!translatableUrlParams.hasOwnProperty(lang)) return params;

        const nextParams = { ...params };
        Object.keys(translatableUrlParams[lang]).forEach(param => {
            if (nextParams.hasOwnProperty(param)) {
                nextParams[param] = translatableUrlParams[lang][param];
            }
        });

        return nextParams;
    }

    render() {
        return (
            <nav className="language-switch">
                {i18next.languages
                    .sort((a, b) => {
                        if (b === defaultLocale) return 1;
                        if (a === defaultLocale) return -1;
                        return 0;
                    })
                    .map(lang => (
                        <LocalizedLink
                            key={`language-switch-${lang}`}
                            className={cn(
                                'language-switch-link primary-contrast huik-text-small',
                                {
                                    active: i18next.language === lang,
                                }
                            )}
                            locale={lang}
                            params={this.formatParams(lang)}
                        >
                            {lang}
                        </LocalizedLink>
                    ))}
            </nav>
        );
    }
}

export default connect(state => ({
    params: state.router.match.params,
    translatableUrlParams: state.l10n.translatableUrlParams,
}))(LanguageSwitch);
