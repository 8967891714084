import {LOCATION_CHANGE} from "connected-react-router";
import {matchPath} from "react-router-dom";
import routes from "../routes";
import qs from "qs";

export default store => next => (action) => {
    if (undefined !== action && action.type === LOCATION_CHANGE) {
        let match = null;
        action.payload.match = {
            path: null,
            url: null,
            isExact: false,
            params: {}
        };
        for (let i = 0, l = routes.length; i < l; i += 1) {
            match = matchPath(action.payload.location.pathname, routes[i]);
            if (match !== null) {
                break;
            }
        }
        action.payload.notFound = true;
        if (match !== undefined && match !== null) {
            action.payload.match = {...match};
            action.payload.notFound = false;
        }
        action.payload.query = {};
        if (action.payload.location && action.payload.location.search) {
            action.payload.query = qs.parse(action.payload.location.search, { ignoreQueryPrefix: true });
        }
    }

    return next(action);
};
