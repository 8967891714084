import React from "react";
import { Provider } from "react-redux";
import PropTypes from "prop-types";
import { hot, setConfig } from "react-hot-loader";
import { ConnectedRouter } from "connected-react-router";
import { HelmetProvider } from 'react-helmet-async';
import App from "./App";

const Root = ({ store, history }) => (
    <Provider store={store}>
        <HelmetProvider>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </HelmetProvider>
    </Provider>
);

Root.propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

setConfig({ pureRender: true });

export default hot(module)(Root);
