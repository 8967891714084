import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import newsletterImage from "../../../assets/img/newsletter.svg";
import {t} from "../../../services/i18n";
import Button from "../../huik/Button/Button";
import {getRouteFromPath} from "../../../routes";
import {PAGE_NEWSLETTER} from "../../../constants/router";

class NewsletterContent extends Component {
    static defaultProps = {
        showModal: () => null,
        path: false
    };

    static propTypes = {
        showModal: PropTypes.func,
        path: PropTypes.string
    };

    static getPageName(props) {
        const {path} = props;
        const route = getRouteFromPath(path);
        if(route === null) return null;
        const {name: pageName} = route;

        return pageName;
    }

    componentDidMount() {
        const {showModal} = this.props;
        const pageName = NewsletterContent.getPageName(this.props);
        if(pageName === PAGE_NEWSLETTER) {
            showModal();
        }
    }

    componentDidUpdate(prevProps) {
        const {showModal} = this.props;
        const prevPageName = NewsletterContent.getPageName(prevProps);
        const pageName = NewsletterContent.getPageName(this.props);
        if(prevPageName !== pageName && pageName === PAGE_NEWSLETTER) {
            showModal();
        }
    }

    render() {
        const {showModal} = this.props;

        return (
            <div className="newsletter">
                <div className="text-center newsletter-top-image">
                    <img src={newsletterImage} alt="" />
                </div>
                <div className="newsletter-image-container">
                    <h3 className="huik-text-title-2">{t('newsletter.title')}</h3>
                    <p>{t('newsletter.text')}</p>
                    <Button
                        onClick={showModal}
                        type="button"
                        size="large"
                        color="primary-contrast"
                    >
                        {t('newsletter.btn')}
                    </Button>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        path: state.router.match.path
    })
)(NewsletterContent);
