import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Menu from "../Menu/Menu";
import cn from "classnames";
import {
    clearAllBodyScrollLocks,
    disableBodyScroll,
    enableBodyScroll
} from "body-scroll-lock";
import LanguageSwitch from "../Internationalisation/LanguageSwitch";
import { PAGE_HOME } from "../../../constants/router";
import LocalizedLink from "../Internationalisation/LocalizedLink";
import Icon from "../../huik/Icon/Icon";
import logoFr from "../../../assets/img/hevaLogo/fr/heva-logo-fr.svg";
import logoEn from "../../../assets/img/hevaLogo/en/heva-logo-en.svg";
import { t } from "../../../services/i18n";

import "./Header.styl";

class Header extends Component {
    static defaultProps = {
        routerKey: null
    };

    static propTypes = {
        routerKey: PropTypes.string
    };

    constructor() {
        super();
        this.targetRef = React.createRef();
        this.targetElement = null;
        this.showMobileMenu = this.showMobileMenu.bind(this);
        this.hideMobileMenu = this.hideMobileMenu.bind(this);
        this.state = {
            isMobileMenuOpen: false
        };
    }

    componentDidMount() {
        this.targetElement = this.targetRef.current;
    }

    componentDidUpdate(prevProps) {
        const { routerKey } = this.props;
        const { isMobileMenuOpen } = this.state;
        if (prevProps.routerKey !== routerKey && isMobileMenuOpen) {
            this.hideMobileMenu();
        }
    }

    componentWillUnmount() {
        clearAllBodyScrollLocks();
    }

    showMobileMenu() {
        disableBodyScroll(this.targetElement);
        this.setState({
            isMobileMenuOpen: true
        });
    }

    hideMobileMenu() {
        enableBodyScroll(this.targetElement);
        this.setState({
            isMobileMenuOpen: false
        });
    }

    render() {
        const { locale } = this.props;
        const logo = locale === "fr" ? logoFr : logoEn;
        const { isMobileMenuOpen } = this.state;
        return (
            <Fragment>
                <header className={cn("header cadre-container")}>
                    <div className="top-nav">
                        <nav className="site-heva-heor-nav" />
                    </div>
                    <div className="bottom-nav">
                        <div className="constant-header">
                            <LocalizedLink
                                page={PAGE_HOME}
                                className="nav-link primary-contrast"
                            >
                                <img
                                    className="logo"
                                    src={logo}
                                    width="112px"
                                    height="55px"
                                    style={{ padding: "13px" }}
                                    alt={t("logoHEVAAlt")}
                                />
                            </LocalizedLink>
                            <div className="mobile-actions">
                                <button
                                    type="button"
                                    className={cn(
                                        "mobile-menu-btn open-mobile-menu",
                                        { hidden: isMobileMenuOpen }
                                    )}
                                    onClick={this.showMobileMenu}
                                >
                                    <Icon>Burger-OFF</Icon>
                                </button>
                                <button
                                    type="button"
                                    className={cn(
                                        "mobile-menu-btn open-mobile-menu",
                                        { hidden: !isMobileMenuOpen }
                                    )}
                                    onClick={this.hideMobileMenu}
                                >
                                    <Icon>Burger-ON</Icon>
                                </button>
                            </div>
                        </div>
                        <div
                            className={cn("navigation", {
                                open: isMobileMenuOpen
                            })}
                            ref={this.targetRef}
                        >
                            <Menu />
                            <LanguageSwitch />
                        </div>
                    </div>
                </header>
                <div className={cn("sticky-header-spacer")} />
            </Fragment>
        );
    }
}

export default connect(state => ({
    routerKey: state.router.location.key,
    locale: state.l10n.locale
}))(Header);
