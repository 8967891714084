import React from "react";
import PropTypes from "prop-types";
import BaseInput from "./BaseInput";

import "./Input.styl";
import cn from "classnames";
import Icon from "../Icon/Icon";

class Input extends BaseInput {
    onKeyDown(event) {
        const { onKeyDown } = this.props;
        if (onKeyDown) {
            onKeyDown(event);
        }
    }

    onKeyUp(event) {
        const { onKeyUp } = this.props;
        if (onKeyUp) {
            onKeyUp(event);
        }
    }

    renderInput() {
        const {
            type,
            name,
            multiline,
            rows,
            defaultValue,
            value,
            color,
            status,
            disabled,
            placeholder,
            required,
            innerRef,
            autoComplete,
            icon
        } = this.props;

        const className = cn(color, status, {
            disabled
        });
        let inputProps = {
            id: this.uniquId,
            name,
            disabled,
            className,
            onChange: this.onChange.bind(this),
            onFocus: this.onFocus.bind(this),
            onBlur: this.onBlur.bind(this),
            onKeyDown: this.onKeyDown.bind(this),
            onKeyUp: this.onKeyUp.bind(this),
            ref: innerRef
        };

        if (value !== null) {
            inputProps.value = value;
        } else {
            inputProps.defaultValue = defaultValue;
        }

        if (multiline) {
            inputProps = Object.assign(
                {
                    rows
                },
                inputProps
            );
            return <textarea {...inputProps}>{defaultValue}</textarea>;
        }
        if (!autoComplete) {
            inputProps.autoComplete = 'off';
        }

        inputProps = Object.assign(
            {
                type,
                placeholder,
                required
            },
            inputProps
        );
        if(icon !== null) {
            inputProps.className = cn(inputProps.className, 'with-icon');
            const iconColor = color.indexOf('contrast') === -1
                ? `${color}-contrast`
                : color.replace('-contrast', '');
            return (
                <div className="huik-input-with-icon">
                    <input {...inputProps} />
                    <Icon color={iconColor}>{icon}</Icon>
                </div>
            );
        }
        return <input {...inputProps} />;
    }

    render() {
        return this.renderContainer(this.renderInput());
    }
}

Input.propTypes = {
    ...BaseInput.propTypes,
    type: PropTypes.string,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    multiline: PropTypes.bool,
    autoComplete: PropTypes.bool,
    rows: PropTypes.number,
    icon: PropTypes.string,
    fileNamePlaceholder: PropTypes.string,
    fileName: PropTypes.string,
};

Input.defaultProps = {
    ...BaseInput.defaultProps,
    type: "text",
    onKeyDown: null,
    onKeyUp: null,
    multiline: false,
    autoComplete: true,
    rows: null,
    icon: null,
    fileNamePlaceholder: null,
    fileName: null
};

export default Input;
