export const setNativeDOMElementValue = (element, value) => {
    const { set: valueSetter } = Object.getOwnPropertyDescriptor(element, 'value') || {};
    const prototype = Object.getPrototypeOf(element);
    const { set: prototypeValueSetter } = Object.getOwnPropertyDescriptor(prototype, 'value') || {};

    if (prototypeValueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter.call(element, value);
    } else if (valueSetter) {
        valueSetter.call(element, value);
    } else {
        throw new Error('The given element does not have a value setter');
    }
};

export function getWindowSize() {
    let width = 0;
    let height = 0;
    if( typeof( window.innerWidth ) === 'number' ) {
        width = window.innerWidth;
        height = window.innerHeight;
    } else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) ) {
        width = document.documentElement.clientWidth;
        height = document.documentElement.clientHeight;
    } else if( document.body && ( document.body.clientWidth || document.body.clientHeight ) ) {
        width = document.body.clientWidth;
        height = document.body.clientHeight;
    }

    return {width, height}
}