import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './FormLabel.styl'

class FormLabel extends React.PureComponent {
    render() {
        const {
            children,
            htmlFor,
            className,
            style,
            disabled,
            size,
        } = this.props;

        return (
            <label htmlFor={htmlFor}
                   className={cn(size, className, {disabled})}
                   style={style}
            >
                {children}
            </label>
        )
    }
}

FormLabel.propTypes = {
    children: PropTypes.node.isRequired,
    htmlFor: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large'])
};

FormLabel.defaultProps = {
    className: null,
    style: {},
    disabled: false,
    size: 'medium'
};

export default FormLabel
