import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './Link.styl'

class Link extends React.PureComponent {
    onClick(e) {
        const {onClick} = this.props;
        if (onClick !== null) {
            onClick(e);
        }
    }

    render() {
        const {
            children,
            component,
            className,
            style,
            color,
            size,
            type,
            href,
            noMargin,
            ...other
        } = this.props;

        if (typeof component === 'string' && href === null) {
            console.error('href must be set with Link');
        }

        const mainClass = type === 'default'
            ? 'huik-link'
            : 'huik-button';
        const classes = cn(mainClass, color, size, className, {
            centered: !Array.isArray(children),
            'no-margin': noMargin
        });

        return React.createElement(component, {
            className: classes, style, href, onClick: this.onClick.bind(this), ...other
        }, children)
    }
}

Link.propTypes = {
    children: PropTypes.node,
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
    className: PropTypes.string,
    style: PropTypes.object,
    color: PropTypes.oneOf(['default', 'primary', 'secondary', 'primary-contrast', 'secondary-contrast', 'success', 'warning', 'error', 'self-defined']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    type: PropTypes.oneOf(['default', 'button']),
    href: PropTypes.string,
    target: PropTypes.string,
    onClick: PropTypes.func,
    noMargin: PropTypes.bool
}

Link.defaultProps = {
    children: null,
    className: null,
    component: 'a',
    style: {},
    color: 'primary',
    size: 'medium',
    type: 'default',
    href: null,
    noMargin: false,
    target: null,
    onClick: null
}

export default Link