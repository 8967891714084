import React, { PureComponent } from 'react';
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import ModalOverlay from "./ModalOverlay";

import './Modal.styl';
import {ModalConsumer} from "../../../providers/ModalProvider";
import Icon from "../../huik/Icon/Icon";

class Modal extends PureComponent {

    static propTypes = {
        children: PropTypes.node.isRequired
    };

    renderModal(isShown, hideModal) {
        const {children} = this.props;
        document.body.style.overflow = isShown ? 'hidden' : null;
        return isShown ? ReactDOM.createPortal(
            <ModalOverlay>
                <div className="modal">
                    <button type="button" className="close-modal" onClick={hideModal}>
                        <Icon>Burger-ON</Icon>
                    </button>
                    {children}
                </div>
            </ModalOverlay>,
            document.body
        ) : null;
    }

    render() {
        return (
            <ModalConsumer>
                {({isShown, hideModal}) => this.renderModal(isShown, hideModal)}
            </ModalConsumer>
        );
    }
}

export default Modal;
