import React, { Component, Suspense } from 'react';
import { Switch } from 'react-router-dom';
import noInternet from 'no-internet';

import RouteWithSubRoutes from './routes/RouteWithSubRoutes';
import routes from './routes';
import DefaultLayout from './views/layouts/DefaultLayout';
import LoadingPage from './views/components/default/LoadingPage/LoadingPage';
import Localizer from 'providers/Localizer';
import ModalProvider from "./providers/ModalProvider";

class App extends Component {
    componentDidMount() {
        // Single check
        noInternet().then(offline => {
            if (offline) {
                this.setState({ isOffline: true });
            }
        });
    }

    render() {
        return (
            <ModalProvider>
                <Localizer>
                    <DefaultLayout>
                        <Suspense fallback={<LoadingPage />}>
                            <Switch>
                                {routes.map((route, i) => (
                                    <RouteWithSubRoutes key={i} {...route} />
                                ))}
                            </Switch>
                        </Suspense>
                    </DefaultLayout>
                </Localizer>
            </ModalProvider>
        );
    }
}

export default App;
