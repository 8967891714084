import {
    PAGE_ARTICLES,
    PAGE_JOB_OFFERS,
    PAGE_PRESTATIONS,
    PAGE_PUBLICATIONS, PAGE_SOLUTIONS,
    PAGE_TEAMS,
} from './router';

export const API_ROUTE_HOME_CONTENTS = `/:locale/home-contents`;
export const API_ROUTE_TEAMS = `/:locale/${PAGE_TEAMS}`;
export const API_ROUTE_PRESTATIONS = `/:locale/${PAGE_PRESTATIONS}`;
export const API_ROUTE_SOLUTIONS = `/:locale/${PAGE_SOLUTIONS}`;
export const API_ROUTE_PUBLICATIONS = `/:locale/${PAGE_PUBLICATIONS}`;
export const API_ROUTE_ARTICLES = `/:locale/${PAGE_ARTICLES}`;
export const API_ROUTE_ARTICLE = `/:locale/${PAGE_ARTICLES}/:id`;
export const API_ROUTE_JOB_OFFERS = `/:locale/${PAGE_JOB_OFFERS}`;
export const API_ROUTE_JOB_OFFER = `/:locale/${PAGE_JOB_OFFERS}/:id`;

export const API_ROUTE_CONTACT = `/contact`;
export const API_ROUTE_JOB_APPLY = `/${PAGE_JOB_OFFERS}/apply`;
export const API_ROUTE_SUBSCRIBE_NEWSLETTER = `/newsletter/subscribe`;

export const API_ROUTE_SOCIAL_POSTS = `/social-posts`;
export const API_ROUTE_YOUTUBE_VIDEOS = `/youtube-videos`;
