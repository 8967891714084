import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import playBtn from "../../../../assets/img/play.svg";

import './YoutubeCard.styl';

class YoutubeCard extends PureComponent {
    static propTypes = {
        item: PropTypes.object.isRequired,
    };

    render() {
        const { item } = this.props;
        const {duration, publishedAt, thumbnailUrl, title, url} = item;

        return (
            <div className="carousel-card youtube-card">
                <a
                    target="_blank"
                    href={url}
                    className="youtube-card-image-container"
                    rel="noopener noreferrer"
                >
                    <img
                        className="youtube-card-image"
                        alt={t('youtubeVideo.altImg')}
                        src={thumbnailUrl}
                    />
                    <img
                        className="youtube-card-play-img"
                        alt={t('youtubeVideo.play')}
                        src={playBtn}
                    />
                    <span className="youtube-card-duration">
                        {duration}
                    </span>
                </a>
                <a
                    target="_blank"
                    href={url}
                    rel="noopener noreferrer"
                >
                    <p
                        className="youtube-card-text"
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                </a>
            </div>
        );
    }
}

export default YoutubeCard;
