import { connectRouter, LOCATION_CHANGE } from 'connected-react-router';
import qs from 'qs';
import ReactGA from 'react-ga';

const INITIAL_EXTRA_STATE_PROPS = {
    match: {
        path: null,
        isExact: false,
        params: {}
    },
    query: null,
    notFound: false
};

function routerReducer(state = null, action) {
    let newState = {};
    if (state === null) {
        newState = {
            ...this.connectedRouterReducer(),
            ...INITIAL_EXTRA_STATE_PROPS,
        };
    } else {
        newState = this.connectedRouterReducer(state, action);
    }
    if (action.type === LOCATION_CHANGE) {
        const extraStateProps = {
            ...INITIAL_EXTRA_STATE_PROPS,
            match: { ...action.payload.match },
            query: action.payload.query,
            notFound: action.payload.notFound
        };
        newState = {
            ...newState,
            ...extraStateProps,
        };

        ReactGA.pageview(
            `${newState.location.pathname}${newState.location.search}`
        );
    }

    return newState;
}

const createRouterReducer = history => {
    const connectedRouterReducer = connectRouter(history);

    return routerReducer.bind({ connectedRouterReducer });
};

export default createRouterReducer;
