import React, {PureComponent, createContext} from 'react';
import PropTypes from 'prop-types';

const ModalContext = createContext();

export default class ModalProvider extends PureComponent {
    static defaultProps = {
        autoOpen: false
    };

    static propTypes = {
        children: PropTypes.node.isRequired,
        autoOpen: PropTypes.bool
    };

    showModal = () => {
        this.setState({
            isShown: true
        })
    };

    hideModal = () => {
        this.setState({
            isShown: false
        });
    };

    state = {
        showModal: this.showModal,
        hideModal: this.hideModal,
        isShown: false
    };

    render() {
        const {props, showModal, hideModal, isShown} = this.state;
        const {children} = this.props;
        return (
            <ModalContext.Provider value={{props, showModal, hideModal, isShown}}>
                {children}
            </ModalContext.Provider>
        );
    }
}

export const ModalConsumer = ModalContext.Consumer;
