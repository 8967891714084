import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import objectFitImages from 'object-fit-images';

import './TwitterCard.styl';

class TwitterCard extends PureComponent {
    static propTypes = {
        item: PropTypes.object.isRequired,
    };

    componentDidMount() {
        objectFitImages('.twitter-card-img');
    }

    render() {
        const { item } = this.props;
        return (
            <div className="carousel-card twitter-card">
                {item.image ? (
                    <img
                        className="twitter-card-img"
                        alt={t('socialCarousel.altImg')}
                        src={item.image}
                        loading="lazy"
                    />
                ) : null}
                <p
                    className="twitter-card-text"
                    dangerouslySetInnerHTML={{ __html: item.text }}
                />
            </div>
        );
    }
}

export default TwitterCard;
