import '@babel/polyfill';
import 'intersection-observer';
// Loads vendors and global style
import './styles/global.styl';
// Loading fonts style sheet separately for webpack to load all files in dist folder
import './assets/fonts/fonts.styl';

import React from 'react';
import {render} from 'react-dom';

import configureStore, {history} from './store/configureStore';
import Root from './Root';
// import ReactGA from "react-ga";
// import pageEn from './lang/en/routing.js';
// import pageFr from './lang/fr/routing.js';
// import {PAGE_POLICY} from "./constants/router";

const store = configureStore();
window.lang = 'fr';

// const rgpd = new RgpdBanner(process.env.GA_TRACKING_ID, {
//     consentCallback: (trackingCode) => {
//         ReactGA.initialize(trackingCode, {
//             debug: false,
//         });
//         ReactGA.set({anonymizeIp: true});
//     },
    // consentOnScroll: false,
    // optionsCallback: () => {
    //     if(history.location.pathname.indexOf('/en') !== -1){
    //         history.push(`/en/${pageEn[PAGE_POLICY]}#cookies-analytics`);
    //     } else {
    //         history.push(`/fr/${pageFr[PAGE_POLICY]}#cookies-analytics`);
    //     }
    // }
// });

render(
    <Root store={store} history={history} />,
    document.getElementById('app')
);

// register service worker
// if (process.env.NODE_ENV === 'production') {
//     if ('serviceWorker' in navigator) {
//         window.addEventListener('load', () => {
//             navigator.serviceWorker
//                 .register('/service-worker.js')
//                 .then(registration => {
//                     // console.log("SW registered: ", registration);
//                 })
//                 .catch(registrationError => {
//                     console.log('SW registration failed: ', registrationError);
//                 });
//         });
//     }
// }
