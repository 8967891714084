export const STATIC_CONTENT_PENDING = 'STATIC_CONTENT_PENDING';
export const STATIC_CONTENT_FULFILLED = 'STATIC_CONTENT_FULFILLED';
export const STATIC_CONTENT_RESET = 'STATIC_CONTENT_RESET';
export const STATIC_CONTENT_ERROR = 'STATIC_CONTENT_ERROR';

export const DYNAMIC_CONTENT_PENDING = 'DYNAMIC_CONTENT_PENDING';
export const DYNAMIC_CONTENT_FULFILLED = 'DYNAMIC_CONTENT_FULFILLED';
export const DYNAMIC_CONTENT_RESET = 'DYNAMIC_CONTENT_RESET';
export const DYNAMIC_CONTENT_ERROR = 'DYNAMIC_CONTENT_ERROR';

export const SINGLE_CONTENT_PENDING = 'SINGLE_CONTENT_PENDING';
export const SINGLE_CONTENT_FULFILLED = 'SINGLE_CONTENT_FULFILLED';
export const SINGLE_CONTENT_RESET = 'SINGLE_CONTENT_RESET';
export const SINGLE_CONTENT_ERROR = 'SINGLE_CONTENT_ERROR';

export const CONTENT_SECTION_ACTIVE_CHANGE = 'CONTENT_SECTION_ACTIVE_CHANGE';

export const SET_IS_WINDOW_SCROLLED = 'SET_IS_WINDOW_SCROLLED';
export const SET_IS_WINDOW_MOBILE = 'SET_IS_WINDOW_MOBILE';
export const SET_KONAMI_ACTIVE = 'SET_KONAMI_ACTIVE';

export const SOCIAL_POSTS_FULFILLED = 'SOCIAL_POSTS_FULFILLED';
