import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LocalizedLink from '../Internationalisation/LocalizedLink'
import {menu} from '../../../routes';
import {t} from '../../../services/i18n';

import './Menu.styl';

class Menu extends Component {
    static propTypes = {

    };

    render() {
        return (
            <nav className="internal-nav">
                {menu.map(menuItem => (
                    <LocalizedLink
                        key={menuItem}
                        page={menuItem}
                        className="nav-link huik-text-small"
                        color="secondary-contrast"
                    >
                        {t(`pages.${menuItem}.title`)}
                    </LocalizedLink>
                ))}
            </nav>
        );
    }
}

export default Menu;
