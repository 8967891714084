import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import cn from 'classnames';
import {t} from "../../../services/i18n";
import Input from "../../huik/Input/Input";
import InputMessage from "../../huik/Input/InputMessage";
import Button from "../../huik/Button/Button";
import { load } from 'recaptcha-v3';

import "./Form.styl";

class Form extends PureComponent {
    static defaultProps = {
        successMessage: null,
        showCaptchaNotice: true
    };

    static propTypes = {
        inputs: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            type: PropTypes.string,
            label: PropTypes.string,
            validate: PropTypes.func,
            group: PropTypes.number,
        })).isRequired,
        submitLabel: PropTypes.string.isRequired,
        submitHandler: PropTypes.func.isRequired,
        successMessage: PropTypes.string,
        showCaptchaNotice: PropTypes.bool
    };

    constructor(props, context) {
        super(props, context);

        this._isMounted = true;
        const inputValues = {};
        props.inputs.forEach(input => {
            inputValues[input.name] = null;
        });
        this.recaptchaInstance = null;
        this.state = {
            inputValues,
            inputErrors: {},
            hasSuccess: false,
            commonError: null,
            isPending: false
        };
    }

    componentDidMount() {
        this.loadRecaptcha();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadRecaptcha() {
        if(this.recaptchaInstance !== null) return;
        load(process.env.RECAPTCHA_KEY).then(recaptcha => {
            if(this._isMounted) {
                this.recaptchaInstance = recaptcha;
            }
        });
    }

    executeRecaptcha() {
        return new Promise((resolve, reject) => {
            if(this.recaptchaInstance === null) reject();

            this.recaptchaInstance.execute().then((token) => {
                resolve(token);
            });
        });
    }

    validateForm() {
        const {inputs} = this.props;
        const {inputValues} = this.state;
        const errors = {};
        inputs.forEach(input => {
            const {name, validate} = input;
            if(validate) {
                const error = validate(inputValues[name]);
                if (error) {
                    errors[name] = error;
                }
            }
        });

        return errors;
    }

    setFormErrors(inputErrors, recaptchaToken) {
        if (!recaptchaToken) {
            inputErrors.captcha = t('errors.emptyRecaptcha');
        }
        this.setState(state => ({...state, inputErrors}));
    }



    handleChange(event, name, type) {
        const {value, files} = event.target;
        let inputValue = value;
        if(type === "file") {
            /* eslint-disable prefer-destructuring */
            inputValue = files[0];
            /* eslint-enable prefer-destructuring */
        }
        this.setState(state => ({
            inputValues: {
                ...state.inputValues,
                [name]: inputValue,
            },
        }));
    }

    handleSubmit(event) {
        event.preventDefault();
        const errors = this.validateForm();
        this.executeRecaptcha().then(token => {
            if(!this._isMounted) return;
            if (Object.keys(errors).length === 0) {
                this.submit(token);
            } else {
                this.setFormErrors(errors, token);
            }
        }).catch(error => {
            console.log(error);
            if(!this._isMounted) return;
            this.setFormErrors(errors, null);
        });
    }

    submit(recaptchaToken) {
        const {submitHandler} = this.props;
        const {inputValues} = this.state;
        this.setState({
            isPending: true,
            commonError: null,
            hasSuccess: false
        });
        inputValues.captcha = recaptchaToken;
        submitHandler(inputValues)
            .then(() => {
                if(!this._isMounted) return;
                this.setState({
                    hasSuccess: true,
                    isPending: false
                });
            })
            .catch(response => {
                if(!this._isMounted) return;
                let commonError = null;
                const inputErrors = {};
                if (typeof response === 'string') {
                    commonError = response;
                } else {
                    Object.keys(response).forEach(name => {
                        inputErrors[name] = response[name];
                    })
                }
                this.setState({
                    hasCommonError: true,
                    commonError,
                    inputErrors,
                    isPending: false
                });
            });
    }

    renderInput(name, type, placeholder) {
        const {inputErrors, inputValues, hasSuccess} = this.state;
        const hasError = !hasSuccess && inputErrors[name];
        let fileName = null;
        if(type === 'file' && inputValues[name]) {
            fileName = inputValues[name].name;
        }

        return (
            <div key={`input-${name}`} className="input-container">
                <Input
                    className="huik-text-small"
                    name={name}
                    onChange={event => this.handleChange(event, name, type)}
                    multiline={type === 'textarea'}
                    label={placeholder}
                    status={hasError ? 'error' : null}
                    type={type}
                    message={
                        hasError
                            ? inputErrors[name].toString()
                            : null
                    }
                    rows={7}
                    fileNamePlaceholder={t('form.defaultFile')}
                    fileName={fileName}
                />
            </div>
        );
    }

    renderGroup(group, inputs) {
        return (
            <div key={`input-group-${group}`} className="input-group-container">
                {this.renderInputs(inputs)}
            </div>
        );
    }

    renderInputs(inputsInGroup = null) {
        const {inputs} = this.props;
        const isGroupRender = inputsInGroup !== null;
        const Inputs = [];
        let groupedInputs = [];
        let currentGroup = null;

        (isGroupRender ? inputsInGroup : inputs).forEach(input => {
            const {name, type, label, group} = input;
            let isGrouped = false;

            if(!isGroupRender && groupedInputs.length > 0 && (!group || currentGroup !== group)) {
                Inputs.push(this.renderGroup(currentGroup, groupedInputs));
                groupedInputs = [];
                currentGroup = null;
            }
            if(!isGroupRender && group && (currentGroup === null || currentGroup === group)) {
                groupedInputs.push(input);
                currentGroup = group;
                isGrouped = true;
            }
            if(isGroupRender || !isGrouped) {
                Inputs.push(this.renderInput(name, type, label));
            }
        });
        if(!isGroupRender && groupedInputs.length > 0) {
            Inputs.push(this.renderGroup(currentGroup, groupedInputs));
        }

        return Inputs;
    }

    render() {
        const {successMessage, submitLabel, showCaptchaNotice} = this.props;
        const {hasSuccess, commonError, inputErrors, isPending} = this.state;
        return (
            <form onSubmit={this.handleSubmit.bind(this)} className="form-container" encType="multipart/form-data">
                {this.renderInputs()}
                {showCaptchaNotice && <small
                    className="recaptcha-disclaimer huik-text-small"
                    dangerouslySetInnerHTML={{
                        __html: t('form.recaptchaNotice'),
                    }}
                />}
                {inputErrors.captcha ? (
                    <InputMessage status="error" className="captcha-error huik-text-small">
                        {inputErrors.captcha}
                    </InputMessage>
                ) : null}
                <div className="actions">
                    {commonError !== null ? <p className="actions-message common-error-message huik-text-small">{commonError}</p> : null}
                    {hasSuccess && successMessage !== null ? (
                        <p className="actions-message success-message huik-text-small">{successMessage}</p>
                    ): null}
                    <Button
                        disabled={isPending || hasSuccess}
                        type="submit"
                        size="large"
                        color="primary-contrast"
                    >
                        {submitLabel}
                    </Button>
                </div>
            </form>
        );
    }
}

export default Form;
