import {
    PAGE_ARTICLE,
    PAGE_ARTICLES,
    PAGE_HOME,
    PAGE_JOB_OFFER,
    PAGE_JOB_OFFERS,
    PAGE_POLICY,
    PAGE_PRESTATIONS,
    PAGE_PUBLICATIONS,
    PAGE_TEAMS,
    PAGE_VOEUX, PAGE_SOLUTIONS, PAGE_VOEUX_2021, PAGE_NEWSLETTER, PAGE_CNIL
} from "../../constants/router";

export default {
    [PAGE_HOME]: '',
    [PAGE_NEWSLETTER]: 'newsletter',
    [PAGE_POLICY]: 'mentions-legales',
    [PAGE_TEAMS]: 'equipes',
    [PAGE_PRESTATIONS]: 'prestations',
    [PAGE_SOLUTIONS]: 'nos-solutions',
    [PAGE_PUBLICATIONS]: 'publications',
    [PAGE_ARTICLES]: 'articles',
    [PAGE_ARTICLE]: 'articles/:slug/:id',
    [PAGE_JOB_OFFERS]: 'offre-d-emploi',
    [PAGE_JOB_OFFER]: 'offre-d-emploi/:slug/:id',
    [PAGE_CNIL]: 'CNIL-et-transparence',
    [PAGE_VOEUX_2021]: 'voeux2021',
    [PAGE_VOEUX]: 'voeux/:annee',
};
