import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Link from '../../huik/Link/Link';
import Icon from "../../huik/Icon/Icon";

import './ExternalLink.styl';

class ExternalLink extends PureComponent {
    static defaultProps = {
        children: null,
    };

    static propTypes = {
        children: PropTypes.node,
        link: PropTypes.string.isRequired,
    };

    render() {
        const { children, link, type } = this.props;
        return (
            <Link
                target="_blank"
                href={link}
                className={`huik-button small external-link ${children.toLocaleLowerCase()}`}
                color="primary-contrast"
                rel="noopener"
            >
                <Icon>
                    {children}
                </Icon>
                {children}
            </Link>
        );
    }
}

export default ExternalLink;
