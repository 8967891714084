import { combineReducers } from 'redux';
import createRouterReducer from './router/routerReducer';
import contentReducer from './content/contentReducer';
import socialPostsReducer from './content/socialPostsReducer';
import windowInformations from './windowInformations/windowInformationsReducer';
import l10n from './l10n'

export default history => {
    const rootReducer = {
        router: createRouterReducer(history),
        content: contentReducer,
        socialPosts: socialPostsReducer,
        windowInformations,
        l10n
    };

    return combineReducers(rootReducer);
};
