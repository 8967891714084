import React, {PureComponent} from 'react';
import './ContactInfos.styl';
import {
    ADDRESS_CITY,
    ADDRESS_COUNTRY,
    ADDRESS_POSTALCODE,
    ADDRESS_STREET1,
    ADDRESS_STREET2,
    EMAIL,
    PHONE_NUMBER,
    SITE_NAME,
} from '../../../../constants/globalParams';
import Icon from "../../../huik/Icon/Icon";

class ContactInfos extends PureComponent {
    render() {
        const cleanPhoneNumber = PHONE_NUMBER.replace(/\s/g, '');
        return (
            <div className="contact-infos">
                <div className="contact-infos-block address">
                    <Icon className="icon">
                        Pin
                    </Icon>
                    <address>
                        {SITE_NAME} <br/>
                        {ADDRESS_STREET1} <br/>
                        {ADDRESS_STREET2} <br/>
                        {ADDRESS_POSTALCODE} {ADDRESS_CITY} <br/>
                        {ADDRESS_COUNTRY}
                    </address>
                </div>
                <div className="contact-infos-block email">
                    <Icon className="icon">
                        mail
                    </Icon>
                    <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
                </div>
                <div className="contact-infos-block">
                    <Icon className="icon">
                        Phone
                    </Icon>
                    <a href={`tel:${cleanPhoneNumber}`}>{PHONE_NUMBER}</a>
                </div>
            </div>
        );
    }
}

export default ContactInfos;
