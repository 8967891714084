import React, { lazy } from 'react';
import qs from 'qs';
import { locales, defaultLocale } from '../lang/config';
import { Redirect } from 'react-router-dom'
import {
    PAGE_ARTICLE,
    PAGE_ARTICLES, PAGE_HOME, PAGE_JOB_OFFER,
    PAGE_JOB_OFFERS,
    PAGE_POLICY,
    PAGE_PRESTATIONS,
    PAGE_PUBLICATIONS,
    PAGE_TEAMS,
    PAGE_VOEUX, PAGE_SOLUTIONS, PAGE_VOEUX_2021, PAGE_NEWSLETTER, PAGE_CNIL
} from "../constants/router";
import {SITE_URL} from "../constants/globalParams";

const NotFoundPage = lazy(() => import('../views/pages/NotFound/NotFound'));
const HomePage = lazy(() => import('../views/pages/HomePage/HomePage'));
const TeamsPage = lazy(() => import('../views/pages/TeamsPage/TeamsPage'));
const JobOffers = lazy(() => import('../views/pages/JobOffersPage/JobOffersPage'));
const ArticlesPage = lazy(() =>
    import('../views/pages/ArticlesPage/ArticlesPage')
);
const ArticlePage = lazy(() =>
    import('../views/pages/ArticlePage/ArticlePage')
);
const PublicationsPage = lazy(() =>
    import('../views/pages/PublicationsPage/PublicationsPage')
);
const PrestationsPage = lazy(() =>
    import('../views/pages/PrestationsPage/PrestationsPage')
);
const SolutionsPage = lazy(() =>
    import('../views/pages/SolutionsPage/SolutionsPage')
);
const MentionsLegales = lazy(() =>
    import('../views/pages/MentionsLegales/MentionsLegales')
);
const CNIL = lazy(() =>
    import('../views/pages/CNIL/CNIL')
);
const JobOffer = lazy(() =>
    import('../views/pages/JobOfferPage/JobOfferPage')
);
const Voeux = lazy(() =>
    import('../views/pages/VoeuxPage/VoeuxPage')
);

export const menu = [
    PAGE_TEAMS,
    PAGE_PRESTATIONS,
    PAGE_SOLUTIONS,
    PAGE_PUBLICATIONS,
    PAGE_ARTICLES,
    PAGE_CNIL,
    PAGE_JOB_OFFERS
];

const routesConfig = [
    {name: PAGE_HOME, component: HomePage, isStatic: false},
    {name: PAGE_NEWSLETTER, component: HomePage, isStatic: false},
    {name: PAGE_POLICY, component: MentionsLegales, isStatic: true},
    {name: PAGE_CNIL, component: CNIL, isStatic: true},
    {name: PAGE_TEAMS, component: TeamsPage, isStatic: false},
    {name: PAGE_PRESTATIONS, component: PrestationsPage, isStatic: false},
    {name: PAGE_SOLUTIONS, component: SolutionsPage, isStatic: false},
    {name: PAGE_PUBLICATIONS, component: PublicationsPage, isStatic: false},
    {name: PAGE_ARTICLES, component: ArticlesPage, isStatic: false},
    {name: PAGE_ARTICLE, component: ArticlePage, parentRoute: PAGE_ARTICLES, isStatic: false},
    {name: PAGE_JOB_OFFERS, component: JobOffers, isStatic: false},
    {name: PAGE_JOB_OFFER, component: JobOffer, parentRoute: PAGE_JOB_OFFERS, isStatic: false},
    {name: PAGE_VOEUX_2021, component: Voeux, isStatic: false},
    {name: PAGE_VOEUX, component: Voeux, isStatic: false},
];

const routes = [
    {
        name: null,
        path: "/",
        exact: true,
        localize: false,
        component: () => <Redirect to={`/${defaultLocale}`} />
    }
];

routesConfig.forEach(route => {
    const {name, component, parentRoute, isStatic} = route;
    Object.keys(locales).forEach(locale => {
        const {routing} = locales[locale];
        if(routing.hasOwnProperty(name)) {
            const routingName = routing[name];
            const path = routingName.length > 0
                ? `/${locale}/${routingName}`
                : `/${locale}`
            ;
            let parentPath = null;
            if(parentRoute !== undefined && routing.hasOwnProperty(parentRoute)) {
                parentPath = routing[parentRoute].length > 0
                    ? `/${locale}/${routing[parentRoute]}`
                    : `/${locale}`
                ;
            }
            routes.push({
                name,
                path,
                exact: true,
                component,
                parent: parentPath,
                isStatic
            });
        }
    });
});
routes.push({ component: NotFoundPage });

export const getRouteFromPath = path => {
    let r = null;
    routes.forEach(route => {
        if(route.path === path) {
            r = route;
        }
    });

    return r;
};

export const getRouteFromPageAndLocale = (page, locale) => {
    let r = null;
    routes.forEach(route => {
        const {name, path} = route;
        if(name === page && path.indexOf(`/${locale}`) === 0) {
            r = route;
        }
    });

    return r;
};

export const getParentRoutesFromPage = page => {
    const parentsPath = [];
    const parentRoutes = [];
    routes.forEach(route => {
        const {name, parent} = route;
        if(page === name) {
            parentsPath.push(parent);
        }
    });
    routes.forEach(route => {
        const {path} = route;
        parentsPath.forEach(parentPath => {
            if(parentPath === path) {
                parentRoutes.push(route);
            }
        })
    });

    return parentRoutes;
};

export const getChildRoutesFromPage = page => {
    const childRoutes = [];
    const pageRoutes = [];
    routes.forEach(route => {
        const {name} = route;
        if(page === name) {
            pageRoutes.push(route);
        }
    });
    routes.forEach(route => {
        const {parent} = route;
        pageRoutes.forEach(pageRoute => {
            const {path} =  pageRoute;
            if(parent === path) {
                childRoutes.push(route);
            }
        })
    });

    return childRoutes;
};

export const getQuery = (query, currentQuery = {}) => {
    const nextQuery = { ...currentQuery, ...query };
    let urlQuery = '';
    if (Object.keys(nextQuery).length > 0) {
        urlQuery = qs.stringify(nextQuery, { addQueryPrefix: true });
    }

    return urlQuery;
};

export const getUrl = (path, params = {}, currentParams = {}) => {
    Object.keys(params).forEach(param => {
        path = path.replace(`:${param}`, params[param]);
    });
    Object.keys(currentParams).forEach(param => {
        path = path.replace(`:${param}`, currentParams[param]);
    });

    return path;
};
export const getFullUrl = (path, params = {}) => {
    return `${SITE_URL}${getUrl(path, params)}`
};



export default routes;
