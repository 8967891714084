import React from "react";
import PropTypes from "prop-types";
import InputHint from "./InputHint";
import InputMessage from "./InputMessage";
import FormLabel from "../FormLabel/FormLabel";
import uniqid from "uniqid";
import cn from "classnames";
import Icon from "../Icon/Icon";

class BaseInput extends React.PureComponent {

    constructor(props, context, uniquId) {
        super(props, context);
        this.uniquId = uniqid(`${props.name}_`);
    }

    onChange(event) {
        const {onChange} = this.props
        if (onChange) {
            onChange(event)
        }
    }

    onFocus(event) {
        const {onFocus} = this.props
        if (onFocus) {
            onFocus(event)
        }
    }

    onBlur(event) {
        const {onBlur} = this.props
        if (onBlur) {
            onBlur(event)
        }
    }

    renderInputHeader() {
        const {label, hint} = this.props;
        if (label == null && hint === null) {
            return null;
        }

        return (
            <div className="huik-input-header">
                {this.renderLabel()}
                {this.renderHint()}
            </div>
        );
    }

    renderInputFileLabel() {
        const {label, size, disabled, fileName, fileNamePlaceholder, status, color} = this.props;

        return (
            <FormLabel htmlFor={this.uniquId} size={size} disabled={disabled}>
                <span className={cn('input-file-element huik-button', status, color)}>
                    <Icon size={size}>Attachment</Icon>
                    <span className="lib">{label}</span>
                </span>
                {
                    fileName !== null || fileNamePlaceholder !== null ? (
                        <span className={cn('input-file-element file-name', status, color, {'no-file': fileName === null})}>
                            {fileName !== null ? fileName : fileNamePlaceholder}
                        </span>
                    ) : null
                }
            </FormLabel>
        )
    }

    renderLabel() {
        const {label, size, type, disabled} = this.props;
        if (label === null) {
            return null;
        }

        if (type === 'file') {
            return this.renderInputFileLabel();
        }

        return (
            <FormLabel htmlFor={this.uniquId} size={size} disabled={disabled}>
                {label}
            </FormLabel>
        );
    }

    renderHint() {
        const {hint, size, disabled} = this.props;
        if (hint === null) {
            return null;
        }

        return (
            <InputHint size={size} disabled={disabled}>
                {hint}
            </InputHint>
        );
    }

    renderMessage() {
        const {message, size, status} = this.props;

        if (message === false) {
            return null;
        }
        let inputMessage = null;
        if (message === null) {
            inputMessage = <InputMessage empty={true}/>;
        } else {
            inputMessage = (
                <InputMessage status={status} size={size}>
                    {message}
                </InputMessage>
            );
        }

        return (
            <div className="huik-input-footer">
                {inputMessage}
            </div>
        );
    }

    renderContainer(Content) {
        const {
            className,
            style,
            size,
            status,
            noMargin,
            type
        } = this.props;
        return (
            <div style={style} className={cn('huik-input-container', status, size, className, {
                'no-margin': noMargin,
                'huik-input-file': type && type === 'file'
            })}>
                {this.renderInputHeader()}
                <div className="huik-input-content">
                    {Content}
                </div>
                {this.renderMessage()}
            </div>
        )
    }
}

BaseInput.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    status: PropTypes.oneOf(['info', 'error', 'success', 'warning']),
    color: PropTypes.oneOf(['primary', 'secondary', 'primary-contrast', 'secondary-contrast']),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    labelProps: PropTypes.object,
    hint: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    hintProps: PropTypes.object,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    noMargin: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    innerRef: PropTypes.func,
};

BaseInput.defaultProps = {
    name: null,
    className: null,
    style: {},
    size: 'medium',
    status: null,
    color: 'primary-contrast',
    message: null,
    label: null,
    labelProps: {},
    hint: null,
    hintProps: {},
    defaultValue: null,
    value: null,
    placeholder: null,
    noMargin: false,
    onChange: null,
    onFocus: null,
    onBlur: null,
    disabled: false,
    readOnly: false,
    required: false,
    innerRef: null
};

export default BaseInput;
