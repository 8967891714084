import React, {PureComponent} from 'react';
import PropTypes from "prop-types";

import './ModalOverlay.styl';

class ModalOverlay extends PureComponent {

    static propTypes = {
        children: PropTypes.node.isRequired
    };

    render() {
        const { children } = this.props;
        return (
            <div className="modal-overlay">{children}</div>
        );
    }
}

export default ModalOverlay;
