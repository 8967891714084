import { defaultLocale, locales } from '../../lang/config'

export function getLocaleFromPath(path) {
    if (path === "/" || path.match(/\//) === null) {
        return defaultLocale
    }
    const locale = path.split('/')[1];
    if(!locales.hasOwnProperty(locale)) {
        return defaultLocale
    }

    return locale;
}

export function switchHtmlLocale (locale) {
    const html =  window.document.documentElement;
    html.lang = locale;
}
