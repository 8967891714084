import {getErrorStatus, getPayloadProp} from './contentReducer';

export const INITIAL_STATE = {
    pending: false,
    fulfilled: false,
    contents: null,
    error: false,
    errorStatus: 500
};

const staticContentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'pending' : {
            return {
                ...state,
                pending: true
            }
        }
        case 'fulfilled' : {
            const contents = getPayloadProp(action, 'contents', []);
            return {
                ...state,
                pending: false,
                error: false,
                fulfilled: true,
                contents
            }
        }
        case 'reset' : {
            return {
                ...state,
                pending: false
            }
        }
        case 'error' : {
            return {
                ...state,
                fulfilled: true,
                pending: false,
                error: true,
                errorStatus: getErrorStatus(action)
            }
        }
    }

    return state;
};

export default staticContentReducer;
