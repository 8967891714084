import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { switchHtmlLocale, getLocaleFromPath } from '../services/i18n/util';
import { changeLocale } from '../actions/LocaleActions';
import numbro from 'numbro';
import moment from 'moment';
import numbroFr from 'numbro/dist/languages/fr-FR.min.js';
import numbroEnGB from 'numbro/dist/languages/en-GB.min.js';
import dayjs from 'dayjs';
import { defaultLocale, locales } from '../lang/config';

class Localizer extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        locale: PropTypes.string.isRequired,
        changeLocale: PropTypes.func.isRequired,
        children: PropTypes.node.isRequired,
    };

    constructor(props) {
        super(props);
        const { history } = props;
        i18next.init({
            lng: defaultLocale,
            debug: process.env.NODE_ENV !== 'production',
            resources: locales,
            fallbackLng: Object.keys(locales),
            interpolation: {
                escapeValue: false, // react already safes from xss
            },
            react: {
                wait: true,
            },
        });
        [numbroFr, numbroEnGB].map(lang => {
            numbro.registerLanguage(lang);
        });
        numbro.registerLanguage({
            ...numbroFr,
            abbreviations: {
                ...numbroFr.abbreviations,
                thousand: 'K',
                million: 'M',
            },
        });
        history.listen(historyLocation => {
            this.setLocale(getLocaleFromPath(historyLocation.pathname));
        });
    }

    componentDidMount() {
        const { location } = this.props;
        this.setLocale(getLocaleFromPath(location.pathname), true);
    }

    /**
     * Set the lang and dir attributes in the <html> DOM element, and
     * initialize our i18n UI library.
     *
     * @param {string} newLocale
     * @param {bool} force
     */
    setLocale(newLocale, force = false) {
        const { locale, changeLocale } = this.props;
        if (force || newLocale !== locale) {
            changeLocale(newLocale);
            switchHtmlLocale(newLocale);
            i18next.changeLanguage(newLocale);
            numbro.setLanguage(i18next.language);
            moment.locale(newLocale);
            dayjs.locale(i18next.language.substring(0, 2));
        }
    }

    render() {
        const { children } = this.props;

        return children;
    }
}

export default withRouter(
    connect(
        state => ({ locale: state.l10n.locale }),
        {
            changeLocale,
        }
    )(Localizer)
);
