export const changeLocale = (locale) => ({
    type: 'CHANGE_LOCALE',
    locale
});
export const addTranslatableUrlParams = (urlParams) => {
    return {
        type: 'ADD_TRANSLATABLE_URL_PARAMS',
        payload: {
            urlParams
        }
    }
};