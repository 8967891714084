import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {Link} from "react-router-dom";

import './Button.styl'

class Button extends React.PureComponent {

    onClick(event) {
        const {onClick} = this.props
        if (onClick) {
            onClick(event)
        }
    }

    render() {
        const {
            children,
            component,
            className,
            style,
            color,
            fullWidth,
            size,
            type,
            disabled,
            href,
            noMargin,
            ...other
        } = this.props

        if (fullWidth) {
            style.width = '100%'
        }

        const classes = cn('huik-button', color, size, className, {
            disabled,
            'no-margin': noMargin,
            centered: !Array.isArray(children)
        })


        if (component !== null) {
            return React.createElement(component, {
                className: classes, style, ...other
            }, children)
        }

        if (type === 'link') {
            if (href === null) {
                console.error('href must be set with link type Button');
            }
            return (
                <Link to={href} className={classes}>
                    {children}
                </Link>
            )
        }

        return (
            /* eslint-disable react/button-has-type */
            <button type={type} style={style} className={classes}
                    disabled={disabled} onClick={this.onClick.bind(this)}>
                {children}
            </button>
            /* eslint-enable react/button-has-type */
        )
    }
}

Button.propTypes = {
    children: PropTypes.node.isRequired,
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
    className: PropTypes.string,
    style: PropTypes.object,
    color: PropTypes.oneOf(['primary', 'secondary', 'primary-contrast', 'secondary-contrast', 'self-defined']),
    fullWidth: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    type: PropTypes.oneOf(['button', 'submit', 'reset', 'link']),
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    href: PropTypes.string,
    noMargin: PropTypes.bool
}

Button.defaultProps = {
    className: null,
    component: null,
    style: {},
    color: 'primary',
    fullWidth: false,
    size: 'medium',
    type: 'button',
    disabled: false,
    onClick: null,
    href: null,
    noMargin: false
}

export default Button