import {getErrorStatus, getPayloadProp} from './contentReducer';

export const INITIAL_STATE = {
    pending: false,
    fulfilled: false,
    error: false,
    errorStatus: 500,
    content: null,
    id: null,
    previous: null,
    next: null,
    others: []
};

const singleContentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'pending' : {
            return {
                ...state,
                pending: true,
                id: action.payload.id
            }
        }
        case 'fulfilled' : {
            const content = getPayloadProp(action, 'content');
            const id = getPayloadProp(action, 'id');
            const previous = getPayloadProp(action, 'previous');
            const next = getPayloadProp(action, 'next');
            const others = getPayloadProp(action, 'others', []);
            if(state.id !== id) {
                return state;
            }
            return {
                ...state,
                pending: false,
                fulfilled: true,
                error: false,
                content,
                previous,
                next,
                others
            }
        }
        case 'reset' : {
            return {
                ...state,
                pending: false,
                id: null
            }
        }
        case 'error' : {
            return {
                ...state,
                fulfilled: true,
                pending: false,
                error: true,
                errorStatus: getErrorStatus(action)
            }
        }
    }

    return state;
};

export default singleContentReducer;
