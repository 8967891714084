import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './InputMessage.styl'
import Icon from "../Icon/Icon";

class InputMessage extends React.PureComponent {
    renderIcon() {
        const {status} = this.props;
        if (status === null) {
            return null;
        }
        if (status === 'info') {
            return (
                <Icon status={status}>
                    Info
                </Icon>
            );
        }
        if (status === 'error' || status === 'warning') {
            return (
                <Icon status={status}>
                    Warning
                </Icon>
            );
        }

        return (
            <Icon status={status}>
                Check-Circle
            </Icon>
        );
    }

    render() {
        const {
            children,
            status,
            className,
            style,
            color,
            size,
            empty
        } = this.props;
        const classes = cn(status, color, size, className);
        if (empty) {
            return <span style={style} className={classes} />;
        }
        return (
            <span style={style} className={classes}>
                {this.renderIcon()}
                <span className="huik-input-message-lib">
                    {children}
                </span>
            </span>
        )
    }
}

InputMessage.propTypes = {
    children: PropTypes.node,
    status: PropTypes.oneOf(['info', 'error', 'success', 'warning']),
    className: PropTypes.string,
    style: PropTypes.object,
    color: PropTypes.oneOf(['primary', 'secondary']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    empty: PropTypes.bool
};

InputMessage.defaultProps = {
    children: null,
    status: null,
    className: null,
    style: {},
    color: 'primary',
    size: 'medium',
    empty: false
};

export default InputMessage