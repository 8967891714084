import React, {PureComponent} from 'react';
import NewsletterModal from "./NewsletterModal";
import {ModalConsumer} from "../../../providers/ModalProvider";
import NewsletterContent from "./NewsletterContent";

import './Newsletter.styl'

class Newsletter extends PureComponent {
    render() {
        return (
            <>
                <ModalConsumer autoOpen={true}>
                    {({showModal}) => (
                        <NewsletterContent showModal={showModal} />
                    )}
                </ModalConsumer>
                <NewsletterModal />
            </>
        );
    }
}

export default Newsletter;
