import React, { Component } from 'react';
import ContactInfos from './ContactInfos/ContactInfos';
import { t } from '../../../services/i18n';
import Form from "../Form/Form";
import {sendContact} from "../../../actions/mailActions";

import './Contact.styl';

class Contact extends Component {

    static sendMail(inputValues) {
        return new Promise((resolve, reject) => {
            sendContact(inputValues)
                .then(() => {
                    resolve();
                })
                .catch(response => {
                    reject(t('contact.sendMailErreur'));
                })
        });
    }

    static validate(value) {
        if(!value) {
            return t('form.champObligatoire');
        }
        return false;
    }

    static getFormInputs() {
        return [
            {name: 'nom', type: 'input', label: t('contact.form.nom'), validate: Contact.validate},
            {name: 'email', type: 'input', label: t('contact.form.email'), validate: Contact.validate},
            {name: 'message', type: 'textarea', label: t('contact.form.message'), validate: Contact.validate}
        ]
    }

    render() {
        return (
            <div className="cadre-container">
                <div className="contact">
                    <div className="contact-col">
                        <h3 className="huik-text-title-2">
                            {t('contact.meetUsTitle')}
                        </h3>
                        <ContactInfos />
                    </div>
                    <div className="contact-col">
                        <h3 className="huik-text-title-2">
                            {t('contact.contactUsTitle')}
                        </h3>
                        <div className="contact-form" id="contact-footer-form">
                            <Form
                                submitLabel={t('actions.send')}
                                submitHandler={Contact.sendMail}
                                inputs={Contact.getFormInputs()}
                                successMessage={t('contact.success')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;
