import React, {PureComponent} from 'react';
import Modal from "../Modal/Modal";
import {t} from "../../../services/i18n";
import Form from "../Form/Form";
import newsletterImage from "../../../assets/img/newsletter.svg";
import {subscribeNewsletter} from "../../../actions/mailActions";


class NewsletterModal extends PureComponent {

    validate = value => {
        if (! value) {
            return t('form.champObligatoire');
        }
        return false;
    };

    inputs = () =>
         [
            {name: 'nom', type: 'input', label: t('newsletter.lastName'), validate: this.validate},
            {name: 'email', type: 'input', label: t('newsletter.email'), validate: this.validate},
            {name: 'company', type: 'input', label: t('newsletter.company'), validate: this.validate}
        ]
    ;

    subscribeNewsletter(inputValues) {
        return new Promise((resolve, reject) => {
            subscribeNewsletter(inputValues)
                .then(() => {
                    resolve();
                })
                .catch(response => {
                    reject(t('newsletter.error'));
                })
        });
    }

    render() {
        return (
            <Modal>
                <div className="text-center">
                    <img src={newsletterImage} alt="" />
                </div>
                <div className="modal-content">
                    <h2 className="huik-text-title-2 text-center">{t(`newsletter.modalTitle`)}</h2>
                    <Form
                        submitLabel={t('newsletter.send')}
                        submitHandler={this.subscribeNewsletter}
                        inputs={this.inputs()}
                        successMessage={t('newsletter.success')}
                        showCaptchaNotice={false}
                    />
                </div>
            </Modal>
        );
    }
}

export default NewsletterModal;
