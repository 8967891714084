import fr from './fr';
import en from './en';
import frRouting from './fr/routing';
import enRouting from './en/routing';

export const defaultLocale = 'fr';

export const locales = {
    fr: {
        code: 'fr',
        codeIso: 'fr_FR',
        translation: fr,
        routing: frRouting,
    },
    en: {
        code: 'en',
        codeIso: 'en_US',
        translation: en,
        routing: enRouting,
    },
};
