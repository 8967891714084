import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet-async';
import Header from '../components/Header/Header';
import {organizationSchema} from './Schema';
import throttle from 'lodash/throttle';
import {SET_IS_WINDOW_MOBILE, SET_IS_WINDOW_SCROLLED, SET_KONAMI_ACTIVE} from '../../constants/actionsTypes';
import Footer from '../components/Footer/Footer';
import navigate from "../../actions/routerActions";
import {getWindowSize} from "../../utils/htmlDom";
import {MOBILE_SIZE} from "../../constants/globalParams";
import KonamiCode from 'konami-code-js'

class DefaultLayout extends Component {
    constructor() {
        super();
        this.onScroll = throttle(this.onScroll.bind(this), 120);
        this.onResize = throttle(this.onResize.bind(this), 120);
        this.isSscrolled = false;
    }

    componentDidMount() {
        const {locale, navigate, setKonamiActive} = this.props
        window.addEventListener('scroll', this.onScroll);
        window.addEventListener('resize', this.onResize);
        const kc = new KonamiCode(() => {
            console.log('konami code');
            setKonamiActive();
        });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
        window.removeEventListener('resize', this.onResize);
    }

    onScroll() {
        const {changedScrolledStatut} = this.props;
        let isSscrolled = false;
        if (window.pageYOffset > 10) {
            isSscrolled = true;
            document.body.classList.add('scrolled');
        } else {
            isSscrolled = false;
            document.body.classList.remove('scrolled');
        }
        if (this.isSscrolled !== isSscrolled) {
            this.isSscrolled = isSscrolled;
            changedScrolledStatut(isSscrolled);
        }
    }

    onResize() {
        const {isMobile, changedIsMobileStatut} = this.props;
        const nextIsMobile = getWindowSize().width <= MOBILE_SIZE;
        if(isMobile !== nextIsMobile) {
            changedIsMobileStatut(nextIsMobile);
        }
    }

    render() {
        const {children} = this.props;
        return (
            <Fragment>
                <Header/>
                <Helmet>
                    <script type="application/ld+json">
                        {organizationSchema()}
                    </script>
                </Helmet>
                <div id="content" className="cadre-container">
                    {children}
                </div>
                <Footer/>
            </Fragment>
        );
    }
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    changedScrolledStatut: PropTypes.func.isRequired,
    changedIsMobileStatut: PropTypes.func.isRequired,
    setKonamiActive: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    isMobile: PropTypes.bool.isRequired
};

export default connect(
    state => ({locale: state.l10n.locale, isMobile: state.windowInformations.isMobile}),
    dispatch => ({
        changedScrolledStatut: scrolled => {
            dispatch({type: SET_IS_WINDOW_SCROLLED, payload: {scrolled}});
        },
        changedIsMobileStatut: isMobile => {
            dispatch({type: SET_IS_WINDOW_MOBILE, payload: {isMobile}});
        },
        navigate: (path, fragment) => {
            dispatch(navigate(path, {}, {}, fragment))
        },
        setKonamiActive: () => {
            dispatch({type: SET_KONAMI_ACTIVE});
        }
    })
)(DefaultLayout);
