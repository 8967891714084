export const PAGE_HOME = 'home';
export const PAGE_NEWSLETTER = 'newsletter';
export const PAGE_POLICY = 'policy';
export const PAGE_TEAMS = 'teams';
export const PAGE_PRESTATIONS = 'prestations';
export const PAGE_SOLUTIONS = 'solutions';
export const PAGE_PUBLICATIONS = 'publications';
export const PAGE_ARTICLES = 'articles';
export const PAGE_ARTICLE = 'article';
export const PAGE_JOB_OFFERS = 'job-offers';
export const PAGE_JOB_OFFER = 'job-offer';
export const PAGE_CNIL = 'CNIL';
export const PAGE_VOEUX_2021 = 'voeux2021';
export const PAGE_VOEUX = 'voeux';

export const PAGE_404 = 'page-404';
