import {SOCIAL_POSTS_FULFILLED} from "../../constants/actionsTypes";

const socialPostsReucer = (state = null, action) => {
    switch (action.type) {
        case SOCIAL_POSTS_FULFILLED : {
            return action.payload;
        }
    }
    return state;
};

export default socialPostsReucer;
