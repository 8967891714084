import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Contact from "../Contact/Contact";
import { getRouteFromPath } from "../../../routes";
import { t } from "../../../services/i18n";
import LocalizedLink from "../Internationalisation/LocalizedLink";
import { PAGE_POLICY } from "../../../constants/router";
import Newsletter from "../Newsletter/Newsletter";
import Carousel from "../Carousel/Carousel";
import {
    API_ROUTE_SOCIAL_POSTS,
    API_ROUTE_YOUTUBE_VIDEOS
} from "../../../constants/apiRoutes";
import TwitterCard from "../Carousel/TwiterCard/TwitterCard";
import YoutubeCard from "../Carousel/YoutubeCard/YoutubeCard";
import logoPoste from "../../../assets/img/LP_SANTE_AUTONOMIE_Logo_horizontal_blanc.svg";
import logoFr from "../../../assets/img/hevaLogo/fr/heva-logo-fr.svg";
import logoEn from "../../../assets/img/hevaLogo/en/heva-logo-en.svg";
import logoDocaposte from "../../../assets/img/docaposte.svg";
import moment from "moment";

import "./Footer.styl";

const mapStateToProps = state => ({
    hasContent: state.content.fulfilled,
    notFound: state.router.notFound,
    path: state.router.match.path,
    locale: state.l10n.locale
});

class Footer extends PureComponent {
    static defaultProps = {
        path: null,
        notFound: false
    };

    static propTypes = {
        hasContent: PropTypes.bool.isRequired,
        path: PropTypes.string,
        notFound: PropTypes.bool
    };

    showContent() {
        const { hasContent, notFound, path } = this.props;
        if (notFound) return true;

        const route = getRouteFromPath(path);
        return hasContent || (route !== null && route.isStatic);
    }

    render() {
        const { locale } = this.props;
        const logo = locale === "fr" ? logoFr : logoEn;

        const now = new Date();
        if (!this.showContent()) {
            return <footer className="cadre-container no-content" />;
        }

        return (
            <footer>
                <Newsletter />
                <Carousel
                    className="social-carousel"
                    title={t("socialCarousel.title")}
                    CardComponent={TwitterCard}
                    fetchUrl={API_ROUTE_SOCIAL_POSTS}
                    externalLinks={[
                        {
                            link: "https://fr.linkedin.com/company/heva",
                            iconName: "Linkedin"
                        },
                        {
                            link: "https://twitter.com/HEVAWEB",
                            iconName: "Twitter"
                        }
                    ]}
                />
                <Carousel
                    className="youtube-video-carousel"
                    title={t("youtubeVideo.title")}
                    CardComponent={YoutubeCard}
                    fetchUrl={API_ROUTE_YOUTUBE_VIDEOS}
                    externalLinks={[
                        {
                            link:
                                "https://www.youtube.com/channel/UCylx9OgDDRgfpAfGiREG0iw",
                            iconName: "Youtube"
                        }
                    ]}
                />
                <Contact />
                <div className="cadre-container">
                    <div className="bottom-footer">
                        <div className="bottom-footer-inner">
                            <div className="link-docaposte">
                                <p className="huik-text-small heva-legacy">
                                    Heva, filiale de Docaposte et marque de
                                </p>
                                <img
                                    className="logo logo-docaposte"
                                    src={logoPoste}
                                    width="188px"
                                    height="86px"
                                    alt={t("logoDocaposteAlt")}
                                />
                            </div>
                            <div>
                                <p className="copyright huik-text-small heva-legacy">
                                    ©️2024. Heva. Tous droits réservés. &#160;
                                    <LocalizedLink
                                        page={PAGE_POLICY}
                                        className="policy-link primary-contrast huik-text-small"
                                    >
                                        {t(`pages.${PAGE_POLICY}.title`)}
                                    </LocalizedLink>
                                    <a
                                        href="https://www.docaposte.com/notre-politique-rse"
                                        className="policy-link primary-contrast huik-text-small"
                                    >
                                        &#160; Politique RSE Docaposte
                                    </a>
                                </p>
                            </div>
                            <div>
                                <a
                                    href="https://www.docaposte.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        className="logo logo-docaposte"
                                        src={logoDocaposte}
                                        width="188px"
                                        height="86px"
                                        alt={t("logoDocaposteAlt")}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default connect(mapStateToProps)(Footer);
