import qs from 'qs';
import { t } from 'i18next';

import {API_ROUTE_CONTACT, API_ROUTE_JOB_APPLY, API_ROUTE_SUBSCRIBE_NEWSLETTER} from '../constants/apiRoutes';
import api from '../utils/api';

export const sendContact = fields =>
    new Promise((resolve, reject) => {
        fields.object = t('contact.object');
        api()
            .post(API_ROUTE_CONTACT, qs.stringify(fields))
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });

export const subscribeNewsletter = fields =>
    new Promise((resolve, reject) => {
        api()
            .post(API_ROUTE_SUBSCRIBE_NEWSLETTER, qs.stringify(fields))
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });

export const sendJobApply = (fields, jobOfferId) =>
    new Promise((resolve, reject) => {
        if(jobOfferId !== null) fields.id = jobOfferId;
        if(fields.currentJob === null) fields.currentJob = '';
        const formData = new FormData();
        Object.keys(fields).forEach(name => {
            formData.append(name, fields[name]);
        });
        api()
            .post(API_ROUTE_JOB_APPLY, formData)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
