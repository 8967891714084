import {connect} from 'react-redux'
import {Link as ReactLink} from 'react-router-dom'
import Link from '../../huik/Link/Link'
import cn from 'classnames';
import React, {Component} from 'react'
import PropTypes from "prop-types";
import {getRouteFromPath, getRouteFromPageAndLocale, getUrl} from '../../../routes';
import {PAGE_HOME} from "../../../constants/router";

class LocalizedLink extends Component {
    clickHandler(isActive, e) {
        const {disabled} = this.props;
        if (disabled) {
            e.preventDefault();
        }
    }

    render() {
        const {
            className,
            color,
            currentLocale,
            locale,
            children,
            page,
            currentPath,
            params,
            query,
            currentUrl,
            urlFragment,
            style
        } = this.props;
        const nextLocale = locale === null
            ? currentLocale
            : locale;
        const currentRoute = getRouteFromPath(currentPath);
        let route = null;
        if(page === null) {
            if (currentRoute !== null) {
                route = getRouteFromPageAndLocale(currentRoute.name, nextLocale);
            }
        } else {
            route = getRouteFromPageAndLocale(page, nextLocale);
        }
        if (route === null) {
            route = getRouteFromPageAndLocale(PAGE_HOME, nextLocale);
        }
        let url = getUrl(route.path, params);
        const isActive = url === currentUrl || (currentRoute && url === currentRoute.parent);
        if (urlFragment !== null) url = `${url}#${urlFragment}`;

        return (
            <Link
                className={cn(className, {
                    'active': isActive
                })}
                color={color}
                component={ReactLink}
                to={url}
                onClick={this.clickHandler.bind(this, isActive)}
                style={style}
            >
                {children}
            </Link>
        )
    }
};

LocalizedLink.defaultProps = {
    locale: null,
    page: null,
    className: '',
    color: 'primary',
    params: {},
    query: {},
    style: {},
    currentPath: null,
    urlFragment: null,
    disabled: false
};

LocalizedLink.propTypes = {
    currentLocale: PropTypes.string.isRequired,
    currentUrl: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    currentPath: PropTypes.string,
    locale: PropTypes.string,
    page: PropTypes.string,
    className: PropTypes.string,
    color: PropTypes.oneOf(['default', 'primary', 'secondary', 'primary-contrast', 'secondary-contrast', 'success', 'warning', 'error', 'self-defined']),
    style: PropTypes.object,
    params: PropTypes.object,
    query: PropTypes.object,
    urlFragment: PropTypes.string,
    disabled: PropTypes.bool
};

export default connect(
    state => ({
        currentLocale: state.l10n.locale,
        currentUrl: state.router.location.pathname,
        currentPath: state.router.match.path
    })
)(LocalizedLink)
