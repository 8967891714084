import {
    PAGE_404,
    PAGE_ARTICLES,
    PAGE_HOME,
    PAGE_JOB_OFFERS,
    PAGE_POLICY,
    PAGE_PRESTATIONS,
    PAGE_PUBLICATIONS,
    PAGE_TEAMS,
    PAGE_VOEUX, PAGE_SOLUTIONS, PAGE_CNIL,
} from '../../constants/router';

export default {
    pages: {
        [PAGE_HOME]: {
            title: 'Home',
            metaTitle: 'Dedicated to analyze Health Data - Heva',
            metaDesc:
                'Heva is dedicated to analyze health data, particularly those from medico-administrative databases such as the PMSI or SNIIRAM',
        },
        [PAGE_POLICY]: {
            title: 'Privacy',
            metaTitle: 'Privacy - Heva',
            metaDesc:
                "Find here the Policy relating to the Heva company's website",
        },
        [PAGE_TEAMS]: {
            title: 'Team',
            metaTitle: 'Team - Heva',
            metaDesc:
                'Heva has put together a team with a wide range of expertise: data managers, statisticians, data scientists engineers, health professionals, developers, designers, etc.',
        },
        [PAGE_PRESTATIONS]: {
            title: 'Our services',
            metaTitle: 'Our Services - Heva',
            metaDesc:
                'Among the solutions offered by Heva: epidemiological, medico-economic or comparative studies, AI, website development, design ',
        },
        [PAGE_SOLUTIONS]: {
            title: 'Solution',
            metaTitle: 'Our Solutions - Heva',
            metaDesc:
                'Heva is an expert in data visualization of open data (Odace and Eureka Med\'), PMSI (ADAPT) and SNDS (Artemis).',
        },
        [PAGE_PUBLICATIONS]: {
            title: 'Publications',
            metaTitle: 'Our publications - Heva',
            metaDesc:
                'Since 2007, Heva has published 103 posters in the most famous scientific congress: ISPOR, ESMO, ASCO, AHA, CPLF, AFCRO…',
        },
        [PAGE_ARTICLES]: {
            title: 'Blog & News',
            metaTitle: 'Blog & News - Heva',
            metaDesc:
                'Find here all the latest news and publications about Heva',
        },
        [PAGE_JOB_OFFERS]: {
            title: 'Join us',
            metaTitle: 'Join us - Heva',
            metaDesc:
                'Is the analyis of health data is a major challenge for you ? Do you want to contribute to this by working in a company on a human scale?',
        },
        [PAGE_404]: {
            title: 'Page not found',
            metaTitle: 'Page not found - Heva',
            metaDesc: 'Meta desc à remplir',
        },
        [PAGE_CNIL]: {
            title: 'CNIL & Transparency',
            metaTitle: 'CNIL & Transparency - Heva',
            metaDesc:
                'CNIL & Transparency',
        },
        [PAGE_VOEUX]: {
            title: 'Best wishes',
            metaTitle: 'Best wishes',
            metaDesc: 'Best wishes',
        }
    },
    logoHEVAAlt: "Logo Heva",
    logoDocaposteAlt: "Logo Docaposte",
    seo: {
        defaultMetaTitle: 'Dedicated to analyze Health Data - Heva',
        defaultMetaDesc:
            'Heva is dedicated to analyze health data, particularly those from medico-administrative databases such as the PMSI or SNIIRAM',
    },
    errors: {
        emptyRecaptcha: 'Please confirm the captcha',
        tryagain: 'Oops, an error has occurred. Please try again.',
    },
    actions: {
        back: 'Back',
        submit: 'Confirm',
        suivant: 'Next',
        precedent: 'Back',
        send: 'Send',
    },
    form: {
        recaptchaNotice: `This site is protected by reCAPTCHA as well as <a target="_blank" rel="noopener" href="https://policies.google.com/privacy">Google's privacy policy</a> and <a target="_blank" rel="noopener" href="https://policies.google.com/terms">terms of use</a>`,
        champObligatoire: 'This field is required!',
        defaultFile: 'File name',
    },
    contact: {
        object: 'Contact form',
        success: 'Your message has been sent successfully.',
        sendMailErreur: 'An error has occured, your message could not be sent.',
        contactUsTitle: 'Contact us',
        meetUsTitle: 'Meet us',
        form: {
            nom: 'Name',
            email: 'Email address',
            message: 'Message',
        },
    },
    staticContent: {
        "eureka-med": {
            link: "Try Eureka Med'",
        },
        odace: {
            link: 'Try ODACE',
        },
        adapt: {
            inner_link: 'CNIL & Transparency',
            link: 'Try ADAPT',
        },
        artemis: {
            link: "Try ARTEMIS",
        }
    },
    content: {
        publications: 'Publication',
        publications_plural: 'Publications',
        articles: 'Article',
        articles_plural: 'Articles',
        articlePlusAncien: 'Older Article',
        articlePlusRecent: 'Newer Article',
        articleInteressant: 'These articles may be of interest to you',
        share: 'Share',
        chargementModule: "Chargement du module...",
        chargementModuleError: "Le module n'a pas pu être chargé."
    },
    tags: {
        publiType: {
            poster: 'Poster',
            article: 'Article',
            communication: 'Oral com'
        },
    },
    jobOffer: {
        defaultObject: 'Spontaneous candidacy',
        applyObject: 'Response to the job offer #{{id}}',
        sendMailErreur:
            'Your application could not be sent, please contact us directly at the following address: contact@hevaweb.com.',
        success: 'Your application has been sent',
        postuler: 'Apply',
        form: {
            nom: 'Last name',
            prenom: 'First name',
            email: 'Email address',
            currentJob: 'Current job',
            tel: 'Phone',
            cv: 'Join your resume',
            message: 'Cover letter',
        },
        description: {
            title: 'Your position does not appear in the list?',
            content:
                '<p>Are you convinced that the exploitation of health data is a major issue and do you want to contribute to it?  Would you like to work in a collaborative company on a human scale? Join Heva.</p><p>We recruit all year round engineers, health professionals, developers, biostatisticians, designers, etc.</p>',
        },
    },
    socialCarousel: {
        title:  'News',
        altImg: 'Heva Articles',
    },
    youtubeVideo: {
        title:  'Videos',
        altImg: "Vidéo Youtube thumbnail",
        play: "Play"
    },
    page404: {
        title: 'Oops, this page does not exist',
        subtitle: 'Are you lost in all this data?',
        content:
            'Come back to the real world by clicking {{home_link}} ou {{contact_link}}.',
        home_link: 'here',
        contact_link: 'contact us',
    },
    page500: {
        title: 'Oops, an error has occured',
        subtitle: 'The server produced an unexpected error',
        content:
            'Come back to the real world by clicking {{home_link}} ou {{contact_link}}.',
        home_link: 'here',
        contact_link: 'report',
    },
    voeux2021: {
        pageTitle: 'Best wishes 2021',
        start: 'Let’s go !',
        text: {
            wait: 'Bientôt disponible, Merci de revenir le 15 décembre<br />pour nous démasquer !',
            helpMessage: 'Find your colleagues<br />behind their masks !',
            home: {
                ligne1: 'In <strong>2021</strong>',
                ligne2: 'can we change',
                ligne3: 'the <strong>mask</strong> ?'
            },
            fete: {
                title: 'Consultants <br />and Directors',
                subtitle: 'They get dressed up to the nines'
            },
            hero: {
                title: 'Data<br />Managers',
                subtitle: 'They save your data'
            },
            explorateurs: {
                title: 'Méthodes<br />& Stats',
                subtitle: 'They explore the world of stats'
            },
            espace: {
                title: 'Data<br />Scientists',
                subtitle: 'They innovate with AI'
            },
            geeks: {
                title: 'Digital',
                subtitle: 'They enhance your results'
            },
            final: {
                ligne1: 'The whole team',//The whole Heva team wishes you all the best for 2021! See you soon & stay safe
                ligne2: 'wishes you all<br />the best for 2021 !',
                ligne3: 'See you soon & stay safe.'
            }
        }
    },
    voeux2022: {
        pageTitle: 'Best wishes 2022',
        passHeva: "to activate your Heva pass",
        pageSuivant: "to go to the following page",
        maintenez: "hold your",
        maintenezMobile: "",
        space: "spacebar",
        appuye: "hold",
        attente: "Rendez-vous à partir du lundi 13 décembre",
        rejouer: "play again",
        page2: {
            text: "An innovation jab to get a stroke of genius"
        },
        page3: {
            text: "and nimbly handle health data"
        },
        page4: {
            text: "in 2022, the whole team wishes you to enjoy your Heva pass"
        }
    },
    crosswords: {
        actions: {
            checkThis: "Check this",
            revealThis: "Reveal this",
            clearThis: "Clear this",
            checkAll: "Check all",
            revealAll: "Reveal all",
            clearAll: "Clear all",
        },
        clues: {
            title: {
                row: "Across",
                col: "Down",
            },
            matching: "To construct an artificial control group, each treated unit with a non-treated unit of similar characteristics",
            confound: "When an variable influences both the hypothesized cause and effect",
            latent: "A random variable which you can’t observe; Derived from the latin word latēre which means hidden",
            cox: "Model used on survival data",
            confindexence: "Never present a parameter estimate without this interval",
            bootstrap: "Used as an alternative to inference when a parametric assumption is in doubt - Random sampling with replacement",
            censoring: "This occurs when analyzing time to event data in which we do not observe all the events before the end of the study",
            dataviz: "Statistical analyses are not used to their full potential without this! Well, Heva believes so",
            power: "The probability of finding an effect, assuming that the effect is truly there",
            typei: "The error of the rejection of a true null hypothesis",
            git: "To ensure traceability, Heva uses this popular version control system ",
            linear: "This type of regression is 'simple'",
            kaplanmeier: "Which non-parametric estimate is displayed here ?",
            prevalence: "The proportion of a particular population found to be affected by a medical condition",
            heva: "Based in Lyon, France, your favourite team to conduct your health analyses",
            random: "This forest is full of decision trees",
            median: "Second quartile",
            pvalue: "In 2016, the American Statistical Association (ASA) released a Statement on the proper use and interpretation of this",
            model: "A mathematically formalized way to approximate reality",
            meta: "This statistical analysis combines the results of multiple scientific studies ",
            mean: "The sum of the sampled values divided by the number of items in the sample",
            tests: "We perform many of these on real world data",
            root: "In this form, the standard deviation is derived from the variance",
            anova: "Analysis of variance",
            poisson: "This fishy distribution can be used to model count data",
        }
    },
    autoencoder: {
        patient: 'Patient',
        parcoursLabel1: 'Drug 1',
        parcoursLabel2: 'Drug 2',
        parcoursLabel3: 'Specialized doctor',
        parcoursLabel4: 'General practitioner',
        indexColonne: 'T{{index}}',
        malade: "Sick",
        nonMalade: "Healthy",
        nonMaladeDetail: "or too sick",
        patientMalade: "Sick patient",
        patientMaladeDescription: "The highlighted events show how the algorithm was able to identify the disease A.",
        patientNonMalade: "Patient not sick",
        patientNonMaladeDescription: "On this care pathway, there are not enough decisive events (not sure to be sick) or too many events (too sick)...",
        runModelTitle: "Check the events",
        runModelDescription: "You can select the events of your choice or automatically generate a patient:",
        runModelAction: "Check",
        runModelPending: "Processing",
        resetAction: "Reset",
        testCards: "Patient cards to classify",
        results: "Result",
        dropHere: "Drag &<br />drop"
    },
    newsletter: {
        modalTitle: "We inform you about health data",
        lastName: "Your name",
        email: "Your eMail",
        company: "Your company",
        send: "Send",
        error: "An error occurred while subscribing to the newsletter.",
        success: "We have added you to the newsletter.",
        title: "We inform you",
        text: "We share essential health and company data with you once a month!",
        btn: "I subscribe to the newsletter"
    }
};
