import {
    ADDRESS_CITY,
    ADDRESS_COUNTRY,
    ADDRESS_POSTALCODE,
    ADDRESS_STREET1,
    ADDRESS_STREET2,
    FACEBOOK_URL,
    LINKEDIN_URL,
    PHONE_NUMBER,
    SITE_NAME,
    SITE_URL,
    TWITTER_URL,
} from '../../constants/globalParams';
import HevaLogo from '../../assets/img/hevaLogo/logo-schema';

export const organizationSchema = () => `
{
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "${SITE_NAME}",
    "url": "${SITE_URL}",
    "logo": "${SITE_URL}${HevaLogo}",
    "sameAs": [
        "${LINKEDIN_URL}",
        "${TWITTER_URL}",
        "${FACEBOOK_URL}"
    ],
    "address": {
        "@type": "PostalAddress",
        "addressCountry": "${ADDRESS_COUNTRY}",
        "addressLocality": "${ADDRESS_CITY}",
        "postalCode": "${ADDRESS_POSTALCODE}",
        "streetAddress": "${ADDRESS_STREET1} ${ADDRESS_STREET2}"
    },
    "contactPoint": [
        {
            "@type": "ContactPoint",
            "telephone": "${PHONE_NUMBER}",
            "contactType": "customer service"
        }
    ]
}
`;
