import {push, replace} from "connected-react-router";
import {getQuery, getUrl} from "../routes";

const navigate = (path = null, params = {}, query = {}, fragment = null, history = true) => (dispatch, getState) => {
    const { router } = getState();

    let nextPath = path;
    if (path === null) {
        nextPath = router.match.path;
    }

    const samePage = nextPath === router.match.path;
    let nextQuery = getQuery(query);
    if (samePage) {
        nextPath = getUrl(nextPath, params, router.match.params);
        nextQuery = getQuery(query, router.query);
    } else {
        nextPath = getUrl(nextPath, params);
    }

    let nextUrl = `${nextPath}${nextQuery}`;
    if (fragment) {
        nextUrl += `#${fragment}`
    }
    if (!history) {
        dispatch(replace(nextUrl));
    } else {
        dispatch(push(nextUrl));
    }
};

export default navigate;

