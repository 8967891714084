import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import sizes from "../../../styles/config/_sizes.json";
import cn from "classnames";
import api from "../../../utils/api";
import Slider from "react-slick";
import ExternalLink from "./ExternalLink";
import { defaultLocale } from '../../../lang/config';

import "./Craousel.styl";

class Carousel extends Component {
    static defaultProps = {
        externalLinks: [],
        className: '',
        lang: defaultLocale
    }

    static propTypes = {
        title: PropTypes.string.isRequired,
        fetchUrl: PropTypes.string.isRequired,
        CardComponent: PropTypes.elementType.isRequired,
        className: PropTypes.string,
        externalLinks: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.string,
                iconName: PropTypes.string
            })
        ),
        lang: PropTypes.string
    };

    static carouselSettings = {
        dots: true,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        responsive: [
            {
                breakpoint: parseInt(
                    sizes.$huikBreakpointMedium.replace('px', ''),
                    10
                ),
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: parseInt(
                    sizes.$huikBreakpointSmall.replace('px', ''),
                    10
                ),
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    _isMounted = false;

    constructor(props, context) {
        super(props, context);
        this.state = {
            queryLang: props.lang,
            pending: false,
            items: null
        };
    }

    fetchItems(fetchUrl, lang) {
        this.setState({
            pending: true,
            queryLang: lang
        });
        api()
            .get(fetchUrl, {
                params: {
                    html_tags: true,
                    count: 15,
                    lang
                },
            })
            .then(response => {
                if (this._isMounted && this.state.queryLang === lang) {
                    this.setState({
                        pending: false,
                        items: response.data
                    });
                }
            })
            .catch(error => {
                console.error(error);
                if (this._isMounted) {
                    this.setState({
                        pending: false,
                        items: []
                    });
                }
                return false;
            });
    }

    componentDidMount() {
        const {fetchUrl, lang} = this.props;
        const {pending, items} = this.state;
        if(items !== null || pending) return;

        this._isMounted = true;

        this.fetchItems(fetchUrl, lang);
    }

    componentDidUpdate(prevProps, prevState) {
        const {fetchUrl, lang} = this.props;
        const {lang: prevLang} = prevProps;
        if(prevLang !== lang) {
            this.fetchItems(fetchUrl, lang);
        }
    }

    render() {
        const {title, className, externalLinks, CardComponent} = this.props;
        const {items} = this.state;

        if(items === null) return null;

        return (
            <div className={cn('carousel-container cadre-container', className)}>
                <div className="carousel-wrapper">
                    <div className="carousel">
                        <div className="carousel-title">
                            <h3 className="title huik-text-title-2">
                                {title}
                            </h3>
                            <div className="external-links">
                                {externalLinks.map(({link, iconName}) => (
                                    <ExternalLink key={iconName} link={link}>
                                        {iconName}
                                    </ExternalLink>
                                ))}
                            </div>
                        </div>
                        <Slider
                            {...Carousel.carouselSettings}
                            className="carousel-content"
                        >
                            {items.map((item, key) => (
                                <CardComponent
                                    key={key}
                                    item={item}
                                />
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        lang: state.l10n.locale,
    })
)(Carousel)
