import {
    SET_IS_WINDOW_MOBILE,
    SET_IS_WINDOW_SCROLLED,
    SET_KONAMI_ACTIVE
} from "../../constants/actionsTypes";
import {getWindowSize} from "../../utils/htmlDom";
import {MOBILE_SIZE} from "../../constants/globalParams";

const INITIAL_STATE = {
    scrolled: false,
    isMobile: getWindowSize().width <= MOBILE_SIZE,
    isKonamiActive: false
};
const windowInformationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_IS_WINDOW_SCROLLED : {
            if(action.payload.scrolled !== state.scrolled) {
                return {...state, scrolled: action.payload.scrolled}
            }
            break;
        }
        case SET_IS_WINDOW_MOBILE : {
            if(action.payload.isMobile !== state.isMobile) {
                return {...state, isMobile: action.payload.isMobile}
            }
            break;
        }
        case SET_KONAMI_ACTIVE: {
            return {...state, isKonamiActive: true}
        }
    }

    return state;
};

export default windowInformationsReducer;
