import { LOCATION_CHANGE } from 'connected-react-router';
import {
    STATIC_CONTENT_PENDING,
    STATIC_CONTENT_FULFILLED,
    STATIC_CONTENT_RESET,
    DYNAMIC_CONTENT_PENDING,
    DYNAMIC_CONTENT_FULFILLED,
    DYNAMIC_CONTENT_RESET,
    SINGLE_CONTENT_FULFILLED,
    SINGLE_CONTENT_RESET,
    SINGLE_CONTENT_PENDING,
    STATIC_CONTENT_ERROR,
    DYNAMIC_CONTENT_ERROR,
    SINGLE_CONTENT_ERROR,
    CONTENT_SECTION_ACTIVE_CHANGE,
} from '../../constants/actionsTypes';

import staticContentReducer, {INITIAL_STATE as STATIC_INITIAL_STATE} from './staticContentReducer';
import dynamicContentReducer, {INITIAL_STATE as DYNAMIC_INITIAL_STATE} from './dynamicContentReducer';
import singleContentReducer, {INITIAL_STATE as SINGLE_INITIAL_STATE} from './singleContentReducer';
import {
    PAGE_ARTICLE,
    PAGE_ARTICLES,
    PAGE_HOME,
    PAGE_JOB_OFFER,
    PAGE_JOB_OFFERS,
    PAGE_PRESTATIONS,
    PAGE_PUBLICATIONS, PAGE_SOLUTIONS,
    PAGE_TEAMS
} from "../../constants/router";
import {getRouteFromPath} from "../../routes";

const INITIAL_STATE = {
    pending: false,
    fulfilled: false,
    activeSection: null,
    [PAGE_HOME]: STATIC_INITIAL_STATE,
    [PAGE_TEAMS]: STATIC_INITIAL_STATE,
    [PAGE_PRESTATIONS]: STATIC_INITIAL_STATE,
    [PAGE_SOLUTIONS]: STATIC_INITIAL_STATE,
    [PAGE_PUBLICATIONS]: DYNAMIC_INITIAL_STATE,
    [PAGE_ARTICLES]: DYNAMIC_INITIAL_STATE,
    [PAGE_JOB_OFFERS]: DYNAMIC_INITIAL_STATE,
    [PAGE_ARTICLE]: SINGLE_INITIAL_STATE,
    [PAGE_JOB_OFFER]: SINGLE_INITIAL_STATE
};

export const getPayloadProp = (action, propName, defaultValue = null) => {
    return action.hasOwnProperty('payload') &&
        action.payload.hasOwnProperty(propName) &&
        action.payload[propName] !== null
            ? action.payload[propName]
            : defaultValue
    ;
};

export const getErrorStatus = (action) => {
    let status = 500;
    if(action.payload.hasOwnProperty('status')) {
        status = parseInt(action.payload.status, 10);
    }

    return status;
};

const contentReducer = (state = INITIAL_STATE, action = { type: null }) => {
    switch (action.type) {
        case LOCATION_CHANGE : {
            if(action.payload.hasOwnProperty('match') &&
                action.payload.match.hasOwnProperty('path')) {
                const route = getRouteFromPath(action.payload.match.path);
                if(route !== null && state.hasOwnProperty(route.name) &&
                    !state[route.name].fulfilled) {
                    return {
                        ...state,
                        fulfilled: false,
                        activeSection: null
                    };
                }
            }
            break;
        }
        case CONTENT_SECTION_ACTIVE_CHANGE : {
            const {activeSection} = action.payload;
            return {
                ...state,
                activeSection
            };
        }
        /*
         * STATIC CONTENT PAGE
         */
        case STATIC_CONTENT_PENDING :
            return {
                ...state,
                pending: true,
                [action.contentType]: staticContentReducer(
                    state[action.contentType],
                    {
                        ...action,
                        type: 'pending'
                    }
                )
            };
        case STATIC_CONTENT_FULFILLED :
            return {
                ...state,
                pending: false,
                fulfilled: true,
                [action.contentType]: staticContentReducer(
                    state[action.contentType],
                    {
                        ...action,
                        type: 'fulfilled'
                    }
                )
            };
        case STATIC_CONTENT_RESET :
            return {
                ...state,
                [action.contentType]: staticContentReducer(
                    state[action.contentType],
                    {
                        ...action,
                        type: 'reset'
                    }
                )
            };
        case STATIC_CONTENT_ERROR :
            return {
                ...state,
                fulfilled: true,
                pending: false,
                [action.contentType]: staticContentReducer(
                    state[action.contentType],
                    {
                        ...action,
                        type: 'error'
                    }
                )
            };
        /*
         * DYNAMIC CONTENT PAGE
         */
        case DYNAMIC_CONTENT_PENDING :
            return {
                ...state,
                pending: true,
                [action.contentType]: dynamicContentReducer(
                    state[action.contentType],
                    {
                        ...action,
                        type: 'pending'
                    }
                )
            };
        case DYNAMIC_CONTENT_FULFILLED :
            return {
                ...state,
                pending: false,
                fulfilled: true,
                [action.contentType]: dynamicContentReducer(
                    state[action.contentType],
                    {
                        ...action,
                        type: 'fulfilled'
                    }
                )
            };
        case DYNAMIC_CONTENT_RESET :
            return {
                ...state,
                [action.contentType]: dynamicContentReducer(
                    state[action.contentType],
                    {
                        ...action,
                        type: 'reset'
                    }
                )
            };
        case DYNAMIC_CONTENT_ERROR :
            return {
                ...state,
                pending: false,
                fulfilled: true,
                [action.contentType]: dynamicContentReducer(
                    state[action.contentType],
                    {
                        ...action,
                        type: 'error'
                    }
                )
            };
        /*
         * SINGLE CONTENT PAGE
         */
        case SINGLE_CONTENT_PENDING :
            return {
                ...state,
                pending: true,
                [action.contentType]: singleContentReducer(
                    state[action.contentType],
                    {
                        ...action,
                        type: 'pending'
                    }
                )
            };
        case SINGLE_CONTENT_FULFILLED :
            return {
                ...state,
                pending: false,
                fulfilled: true,
                [action.contentType]: singleContentReducer(
                    state[action.contentType],
                    {
                        ...action,
                        type: 'fulfilled'
                    }
                )
            };
        case SINGLE_CONTENT_RESET :
            return {
                ...state,
                [action.contentType]: singleContentReducer(
                    state[action.contentType],
                    {
                        ...action,
                        type: 'reset'
                    }
                )
            };
        case SINGLE_CONTENT_ERROR :
            return {
                ...state,
                pending: false,
                fulfilled: true,
                [action.contentType]: singleContentReducer(
                    state[action.contentType],
                    {
                        ...action,
                        type: 'error'
                    }
                )
            };
    }

    return state;
};

export default contentReducer;
