import chroma from 'chroma-js';
import {getErrorStatus, getPayloadProp} from './contentReducer';

const getTaxonomieColorsPalette = (paramName) => {
    if(paramName === 'at') {
        return [
            '#accb44',
            '#4ca094',
            '#188c9c',
            '#2bbdc8',
            '#326ab1',
            '#4a2572',
            '#a11f7b',
            '#dd255f',
            '#ff3c48',
            '#ff7240',
            '#fabb00'
        ];
    }

    return null;
};

const setTaxonomieColors = (taxonomies) => {
    const taxonomieColors = {};
    taxonomies.forEach(taxonomie => {
        const {paramName, slug} = taxonomie;
        const colorsPalette = getTaxonomieColorsPalette(paramName);
        if(colorsPalette !== null) {
            taxonomieColors[slug] = {};
            taxonomie.items.forEach((item, i) => {
                taxonomieColors[slug][item.slug] = chroma
                    .scale(colorsPalette)
                    .mode('lch')
                    .colors(taxonomie.items.length)[i]
                ;
            });
        } else {
            taxonomieColors[slug] = null;
        }
    });

    return taxonomieColors;
};


export const INITIAL_STATE = {
    pending: false,
    fulfilled: false,
    error: false,
    errorStatus: 500,
    contents: null,
    taxonomies: null,
    taxonomieColors: null,
    filters: null,
    apiUrl: null,
    itemCount: 0,
    pageCount: 0,
    page: 1,
    limit: 10
};

const staticContentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'pending' : {
            return {
                ...state,
                pending: true,
                apiUrl: action.payload.apiUrl
            }
        }
        case 'fulfilled' : {
            const contents = getPayloadProp(action, 'contents', []);
            const taxonomies = getPayloadProp(action, 'taxonomies', []);
            const apiUrl = getPayloadProp(action, 'apiUrl');
            const page = getPayloadProp(action, 'page', 1);
            const limit = getPayloadProp(action, 'limit', 10);
            const itemCount = getPayloadProp(action, 'itemCount', 0);
            const pageCount = getPayloadProp(action, 'pageCount', 0);

            if(state.apiUrl !== apiUrl) {
                return state;
            }

            return {
                ...state,
                fulfilled: true,
                pending: false,
                error: false,
                contents,
                taxonomies,
                taxonomieColors: setTaxonomieColors(taxonomies),
                itemCount,
                pageCount,
                page,
                limit
            }
        }
        case 'reset' : {
            return {
                ...state,
                pending: false,
                apiUrl: null
            }
        }
        case 'error' : {
            return {
                ...state,
                fulfilled: true,
                pending: false,
                error: true,
                errorStatus: getErrorStatus(action)
            }
        }
    }

    return state;
};

export default staticContentReducer;
