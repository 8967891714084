import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './Icon.styl'

function Icon(props) {
    const {
        children,
        className,
        style,
        color,
        size,
        status
    } = props

    return (
        <span className={cn('huik-icon-container', className)}>
            <span style={style} className={cn('huik-icon', `huik-icon-${children}`,
                color, size, status
            )} />
        </span>
    )
}

Icon.propTypes = {
    children: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    status: PropTypes.oneOf(['info', 'error', 'success', 'warning']),
    color: PropTypes.oneOf(['default', 'primary', 'secondary', 'primary-contrast', 'secondary-contrast']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
}

Icon.defaultProps = {
    className: null,
    style: {},
    color: 'default',
    status: null,
    size: 'medium',
}

export default Icon
