export const SITE_NAME = 'Heva';
export const SITE_URL = process.env.SITE_URL || 'https://hevaweb.com';
export const LINKEDIN_URL = 'https://fr.linkedin.com/company/heva';
export const TWITTER_URL = 'https://twitter.com/hevaweb';
export const FACEBOOK_URL = 'https://facebook.com/heva.hevaweb';
export const LOGO_WIDTH = '74';
export const LOGO_HEIGHT = '74';
export const PHONE_NUMBER = '+33 4 72 74 25 60';
export const EMAIL = 'contact@hevaweb.com';
export const ADDRESS_COUNTRY = 'France';
export const ADDRESS_CITY = 'Lyon';
export const ADDRESS_POSTALCODE = 69006;
export const ADDRESS_STREET1 = 'Immeuble 6e sens';
export const ADDRESS_STREET2 = '186 avenue Thiers';

// pagination
export const NB_ARTICLES_BY_PAGES = 10;
export const NB_PUBLICATIONS_BY_PAGES = 15;
export const NB_JOBS_BY_PAGES = 15;

export const MOBILE_SIZE = 960; // Attention cette valeur est liée avec "$huikBreakpointMedium" de huik/_variable.styl
