import {
    PAGE_404,
    PAGE_ARTICLES,
    PAGE_HOME,
    PAGE_JOB_OFFERS,
    PAGE_POLICY,
    PAGE_PRESTATIONS,
    PAGE_PUBLICATIONS,
    PAGE_TEAMS,
    PAGE_VOEUX, PAGE_SOLUTIONS, PAGE_CNIL,
} from '../../constants/router';

export default {
    pages: {
        [PAGE_HOME]: {
            title: 'Accueil',
            metaTitle:
                'Heva - Spécialisé dans l’analyse des données de santé (SNDS)',
            metaDesc:
                "Heva s’est spécialisé dans l'analyse des données de santé et notamment celles issues des bases médico-administratives comme le PMSI ou le SNIIRAM",
        },
        [PAGE_POLICY]: {
            title: 'Mentions légales',
            metaTitle: 'Mentions légales - Heva',
            metaDesc:
                'Retrouvez ici l’ensemble des mentions légales relatives au site internet de la société Heva',
        },
        [PAGE_TEAMS]: {
            title: "L'Équipe",
            metaTitle: "L'équipe - Heva",
            metaDesc:
                'Heva a constitué une équipe aux expertises variées : data managers, biostatisticiens, ingénieurs data scientist, professionnels de santé, développeurs, designers etc.',
        },
        [PAGE_PRESTATIONS]: {
            title: 'Prestations',
            metaTitle: 'Nos prestations - Heva',
            metaDesc:
                'Parmi les prestations proposées par Heva : études épidémiologiques, médico-économiques, comparatives, IA, développement de site web, design',
        },
        [PAGE_SOLUTIONS]: {
            title: 'Nos solutions',
            metaTitle: 'Nos solutions - Heva',
            metaDesc:
                'Heva est une société experte dans la datavisualisation de données open data (Odace et Eureka Med\'), PMSI (ADAPT) et SNDS (Artemis).',
        },
        [PAGE_PUBLICATIONS]: {
            title: 'Publications',
            metaTitle: 'Nos publications - Heva',
            metaDesc:
                'Depuis 2007, Heva publie régulièrement dans les plus prestigieux congrès scientifiques : ISPOR, ESMO, ASCO, AHA, CPLF, AFCRO…',
        },
        [PAGE_ARTICLES]: {
            title: 'Blog & Nouvelles',
            metaTitle: 'Blog & Nouvelles - Heva',
            metaDesc:
                'Retrouvez ici toutes les dernières actualités et publications à propos d’Heva',
        },
        [PAGE_JOB_OFFERS]: {
            title: 'Nous rejoindre',
            metaTitle: 'Nous rejoindre - Heva',
            metaDesc:
                'L’analyse des données de santé constitue pour vous un enjeu majeur ? Vous voulez y contribuer en travaillant dans une entreprise à taille humaine ?',
        },
        [PAGE_404]: {
            title: 'Page introuvable',
            metaTitle: 'Page introuvable - Heva',
            metaDesc:
                "Heva s’est spécialisé dans l'analyse des données de santé et notamment celles issues des bases médico-administratives comme le PMSI ou le SNIIRAM",
        },
        [PAGE_CNIL]: {
            title: 'CNIL & Transparence',
            metaTitle: 'CNIL & Transparence - Heva',
            metaDesc:
                'CNIL & Transparence',
        },
        [PAGE_VOEUX]: {
            title: 'Meilleurs voeux',
            metaTitle: 'Meilleurs voeux',
            metaDesc: 'Meilleurs voeux',
        }
    },
    logoHEVAAlt: "Logo Heva",
    logoDocaposteAlt: "Logo Docaposte",
    seo: {
        defaultMetaTitle:
            'Heva - Spécialisé dans l’analyse des données de santé (SNDS)',
        defaultMetaDesc:
            "Heva s’est spécialisé dans l'analyse des données de santé et notamment celles issues des bases médico-administratives comme le PMSI ou le SNIIRAM",
    },
    errors: {
        emptyRecaptcha: 'Veuillez valider le captcha',
        tryagain: 'Ooops, une erreur est survenue. Veuillez essayer à nouveau.',
    },
    actions: {
        back: 'Retour',
        submit: 'Valider',
        suivant: 'Suivant',
        precedent: 'Précédent',
        send: 'Envoyer',
    },
    form: {
        recaptchaNotice: `Ce site est protégé par reCAPTCHA ainsi que la <a target="_blank" rel="noopener" href="https://policies.google.com/privacy">politique de vie privée</a> et les <a target="_blank" rel="noopener" href="https://policies.google.com/terms">conditions d'utilisation</a> de Google.`,
        champObligatoire: 'Ce champs est obligatoire !',
        defaultFile: 'Nom du fichier',
    },
    contact: {
        object: 'Formulaire de contact',
        success: 'Votre message a bien été envoyé.',
        sendMailErreur:
            "Une erreur est survenue, votre message n'a pu être envoyé.",
        contactUsTitle: 'Nous contacter',
        meetUsTitle: 'Nous rencontrer',
        form: {
            nom: 'Votre nom',
            email: 'Votre email',
            message: 'Votre message pour nous',
        },
    },
    staticContent: {
        "eureka-med": {
            link: "Découvrir Eureka Med'",
        },
        odace: {
            link: 'Découvrir ODACE',
        },
        adapt: {
            inner_link: 'CNIL & Transparence',
            link: 'Découvrir ADAPT',
        },
        artemis: {
            link: "Découvrir ARTEMIS",
        }
    },
    content: {
        publications: 'Publication',
        publications_plural: 'Publications',
        articles: 'Article',
        articles_plural: 'Articles',
        articlePlusAncien: 'Article plus ancien',
        articlePlusRecent: 'Article plus récent',
        articleInteressant: 'Ces articles peuvent vous intéresser',
        share: 'Partager',
        chargementModule: "Chargement du module...",
        chargementModuleError: "Le module n'a pas pu être chargé."
    },
    tags: {
        publiType: {
            poster: 'Poster',
            article: 'Article',
            communication: 'Com orale'
        },
    },
    jobOffer: {
        defaultObject: 'Canditature spontanée',
        applyObject: "Réponse à l'offre d'emploi #{{id}}",
        sendMailErreur:
            "Votre candidature n'a pu être envoyée, veullez svp nous contacter directement à l'adresse suivante : contact@hevaweb.com.",
        success: 'Votre candidature a bien été envoyée.',
        postuler: 'Postuler',
        form: {
            nom: 'Votre nom',
            prenom: 'Votre prénom',
            email: 'Votre email',
            currentJob: 'Poste actuel',
            tel: 'Téléphone',
            cv: 'Joindre votre CV',
            message: 'Votre lettre de motivation',
        },
        description: {
            title: 'Votre poste n’apparait pas dans la liste ?',
            content:
                '<p>Vous êtes persuadés que l’exploitation des données de santé constitue un enjeu majeur et vous voulez y contribuer ?  Vous souhaitez travailler dans une entreprise collaborative à taille humaine ? Rejoignez Heva.</p><p>Nous recrutons toute l’année des ingénieurs, des professionnels de santé, des développeurs, des biostatisticiens, des designers…</p>',
        },
    },
    socialCarousel: {
        title:  'Nos actualités',
        altImg: "Articles d'Heva",
    },
    youtubeVideo: {
        title:  'Nos vidéos',
        altImg: "Vidéo Youtube thumbnail",
        play: "Voir la vidéo"
    },
    page404: {
        title: 'Oooouups, cette page n’existe pas',
        subtitle: 'Vous êtes perdu parmi toutes ces données ?',
        content:
            'Retrouvez le monde réel en cliquant {{home_link}} ou {{contact_link}}.',
        home_link: 'ici',
        contact_link: 'contactez-nous',
    },
    page500: {
        title: 'Oooouups, une erreur est survenue',
        subtitle: 'Le serveur a produit une erreur imprévue',
        content:
            'Retrouvez le monde réel en cliquant {{home_link}} ou {{contact_link}}.',
        home_link: 'ici',
        contact_link: 'signalez-le',
    },
    voeux2021: {
        pageTitle: 'Meilleurs voeux 2021',
        start: 'C’est parti !',
        text: {
            wait: 'Bientôt disponible, Merci de revenir le 15 décembre<br />pour nous démasquer !',
            helpMessage: 'Retrouvez vos collaborateurs<br />sous leurs masques !',
            home: {
                ligne1: 'En <strong>2021</strong>',
                ligne2: 'on change de',
                ligne3: '<strong>masque</strong> ?'
            },
            fete: {
                title: 'Consultants<br />et Direction',
                subtitle: 'Ils se mettent sur leur 31'
            },
            hero: {
                title: 'Data<br />Managers',
                subtitle: 'Ils sauvent vos données'
            },
            explorateurs: {
                title: 'Méthodes<br /> et Stats',
                subtitle: 'Ils explorent le monde de la stat'
            },
            espace: {
                title: 'Data<br />Scientists',
                subtitle: 'Ils innovent grâce à l’IA'
            },
            geeks: {
                title: 'Digital',
                subtitle: 'Ils subliment vos résultats'
            },
            final: {
                ligne1: 'Toute l<span class="apostrophe"> ’</span>équipe',
                ligne2: 'vous présente ses<br />meilleurs voeux !',
                ligne3: 'À bientôt, tout en restant prudents.'
            }
        }
    },
    voeux2022: {
        pageTitle: 'Meilleurs voeux 2022',
        passHeva: "pour activer votre pass Heva",
        pageSuivant: "pour aller à la page suivante",
        maintenez: "maintenez la",
        maintenezMobile: "maintenez",
        space: "touche espace",
        appuye: "appuyé",
        attente: "Rendez-vous à partir du lundi 13 décembre",
        rejouer: "rejouer",
        page2: {
            text: "Injection d’innovation pour bénéficier du génie"
        },
        page3: {
            text: "et manier avec agilité les données de santé"
        },
        page4: {
            text: "en 2022, toute l’équipe vous souhaite de profiter de votre pass heva"
        }
    },
    crosswords: {
        actions: {
            checkThis: "Vérifier la sélection",
            revealThis: "Révéler la sélection",
            clearThis: "Effacer la sélection",
            checkAll: "Vérifier la grille",
            revealAll: "Révéler la grille",
            clearAll: "Effacer la grille",
        },
        clues: {
            title: {
                row: "Horizontal",
                col: "Vertical",
            },
            matching: "Construction d’un groupe contrôle, avec chaque patient traité associé à un patient non traité qui a les mêmes caractéristiques",
            confound: "Une variable non mesurée qui influence à la fois la cause et l'effet hypothétique",
            latent: "Variable aléatoire non observable; Dérivé du mot latin latēre qui signifie caché",
            cox: "Le modèle de survie",
            confindexence: "Ne jamais présenter l’estimation d’un paramètre sans cet intervalle",
            bootstrap: "Utilisé comme une alternative à l’inférence lorsqu’une hypothèse n’est pas respectée – Echantillonnage aléatoire avec remise",
            censoring: "Lors d’une analyse de durée, cet évènement se produit quand les évènements ne sont plus observables avant la fin de l’étude",
            dataviz: "Les analyses statistiques ne sont pas utilisées à leur plein potentiel sans cela! Enfin, Heva en est convaincue.",
            power: "La probabilité de trouver un effet sachant que l’effet est vrai",
            typei: "Cette erreur est le rejet de l’hypothèse nulle alors qu’elle est vraie",
            git: "Pour assurer la traçabilité, Heva utilise cet outil de versioning très populaire ",
            linear: "Ce type de régression est “simple”",
            kaplanmeier: "Quelle estimation non paramétrique est affichée ici ?",
            prevalence: "La proportion d’une population particulière atteinte d’une maladie",
            heva: "Basée à Lyon, en France, votre équipe préférée pour conduire des analyses sur les données de santé",
            random: "Cette forêt-là est pleine d'arbre de décisions",
            median: "Deuxième quartile",
            pvalue: "En 2016, la société américaine de statistique (“American Statistical Association” – ASA) a publié un article sur l’utilisation et l’interprétation appropriées de celle-ci",
            model: "Une manière d’approcher la réalité par les mathématiques",
            meta: "Cette analyse statistique combine les résultats de multiples études scientifiques",
            mean: "La somme des valeurs d’un échantillon divisée par le nombre d’éléments de l’échantillon",
            tests: "Nous en effectuons beaucoup sur des données de vie réelle",
            root: "Sous cette forme, l’écart-type est dérivé de la variance",
            anova: "Analyse de la variance",
            poisson: "Cette distribution “fishy” peut-être utilisée pour modéliser des données de comptage",
        }
    },
    autoencoder: {
        patient: 'Patient',
        parcoursLabel1: 'Médicament 1',
        parcoursLabel2: 'Médicament 2',
        parcoursLabel3: 'Médecin spécialisé',
        parcoursLabel4: 'Médecin généraliste',
        indexColonne: 'T{{index}}',
        malade: "Malade",
        nonMalade: "Non malade",
        nonMaladeDetail: "ou trop malade",
        patientMalade: "Patient malade",
        patientMaladeDescription: "Les événements mis en évidence montrent comment l'algorithme a pu identifier la maladie A.",
        patientNonMalade: "Patient non malade",
        patientNonMaladeDescription: "Sur ce parcours de soin, il n’y a pas assez d'événements décisifs (pas sûr d'être malade) ou trop d'événements (trop malade)...",
        runModelTitle: "Cocher les événements",
        runModelDescription: "Vous pouvez sélectionner les événements de votre choix ou générer automatiquement un patient :",
        runModelAction: "Tester",
        runModelPending: "Analyse",
        resetAction: "Remettre à zéro",
        testCards: "Cartes-patient à tester",
        results: "Résultats",
        dropHere: "Faire<br />glisser"
    },
    newsletter: {
        modalTitle: "Nous vous informons sur les données de santé",
        lastName: "Votre nom",
        email: "Votre eMail",
        company: "Votre entreprise",
        send: "Envoyer",
        error: "Une erreur s'est produite lors de l'inscription à la newsletter.",
        success: "Nous vous avons bien ajouté à la newsletter.",
        title: "Nous vous informons",
        text: "Nous partageons avec vous les informations essentielles des données de santé et de notre société une fois par mois !",
        btn: "Je m'inscris à la newsletter"
    }
};
