import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './InputHint.styl'

class InputHint extends React.PureComponent {
    render() {
        const {
            children,
            className,
            style,
            hintProps,
            disabled,
            color,
            size,
        } = this.props;
        const classes = cn(color, size, className, {disabled});
        let props = {
            className: classes,
            style
        };
        props = {...props, ...hintProps};

        return (
            <span {...props}>
                {children}
            </span>
        )
    }
}

InputHint.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    hintProps: PropTypes.object,
    disabled: PropTypes.bool,
    color: PropTypes.oneOf(['primary', 'secondary']),
    size: PropTypes.oneOf(['small', 'medium', 'large'])
};

InputHint.defaultProps = {
    className: null,
    style: {},
    hintProps: {},
    disabled: false,
    color: 'primary',
    size: 'medium'
};

export default InputHint